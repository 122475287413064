// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1YnfB49zLBVSFGp-Hpx8rW {\n  min-height: 100vh;\n}\n._3UsublLrNyxn_zQwdCONLa {\n  padding: 0;\n  background-color: white;\n  margin-bottom: 2px;\n}\n._1wpn6CN2-5BEP66i42EB8D {\n  margin-left: 2px;\n}\n._24x7sLHgBVKiy303UAYQeW {\n  background-color: white;\n  -moz-box-shadow: 0 0 10px #000000;\n  -webkit-box-shadow: 0 0 10px #000000;\n  box-shadow: 1px 0 10px 0px rgba(0, 0, 0, 0.15);\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"root": "_1YnfB49zLBVSFGp-Hpx8rW",
	"header": "_3UsublLrNyxn_zQwdCONLa",
	"layout": "_1wpn6CN2-5BEP66i42EB8D",
	"sidebar": "_24x7sLHgBVKiy303UAYQeW"
};
module.exports = exports;
