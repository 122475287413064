import { Locale as AntdLocale } from 'antd/es/locale-provider'
import { PickerLocale } from 'antd/lib/date-picker/generatePicker'
import { BehaviorSubject } from 'rxjs'
import { AppLocale } from '~i18n/types'
import useFacade from '~lib/base/useFacade'

export type Strings = {
  app: AppLocale
  antd: AntdLocale
  date: PickerLocale
}

// https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/i18n/TranslationUtils.ts#L31

interface AllNavigatorLanguage extends NavigatorLanguage {
  browserLanguage?: string
  userLanguage?: string
}

const resolveBrowserLocale = (): keyof LocaleProvider => {
  const {
    language,
    browserLanguage,
    userLanguage
  } = window.navigator as AllNavigatorLanguage
  const locale = (
    language ||
    browserLanguage ||
    userLanguage ||
    DEFAULT_LOCALE
  ).replace('-', '_')

  // TODO: check if browser locale is supported
  if (locale !== DEFAULT_LOCALE) {
    return DEFAULT_LOCALE
  }

  return locale
}
// locales

const DEFAULT_LOCALE = 'es_ES'

type LocaleProvider = {
  es_ES: Promise<{ default: object }>
}

const LOCALES: LocaleProvider = {
  es_ES: import('~i18n/es_ES')
}

const ANTD_LOCALES: LocaleProvider = {
  es_ES: import('antd/es/locale/es_ES')
}

const DATE_LOCALES: LocaleProvider = {
  es_ES: import('dayjs/locale/es')
}

// subject and init (async)

const stringsSubject = new BehaviorSubject<Strings>({
  app: {} as AppLocale,
  antd: {} as AntdLocale,
  date: {} as PickerLocale
})

export const initStrings = (nextLocale?: keyof LocaleProvider) => {
  const locale = nextLocale ?? resolveBrowserLocale()
  return Promise.all([
    LOCALES[locale],
    ANTD_LOCALES[locale],
    DATE_LOCALES[locale]
  ]).then((results) => {
    stringsSubject.next({
      app: results[0].default as AppLocale,
      antd: results[1].default as AntdLocale,
      date: results[2].default as PickerLocale
    })
  })
}

export const getStrings = (): Strings => stringsSubject.getValue()

// export facade

export default function useStrings(): [
  Strings,
  (nextLocale: keyof LocaleProvider) => void
] {
  const [strings] = useFacade(stringsSubject)

  const setter = (nextLocale: keyof LocaleProvider) => {
    initStrings(nextLocale)
  }

  return [strings, setter]
}
