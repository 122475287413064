// auth

export type AuthDTO = {
  id_token: string
  access_token: string
  token_type: string
}

// tenant

export type TenantDTO = {
  id: string
  name: string
  contact: string
  admin: string
  oneSignal: boolean
  stripe: boolean
  tags: Array<TagDTO>
  mandatory_fields: object
  allowed_roles: Array<string>
}

// customer

export enum CustomerGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export type CustomerDTO = {
  id: string
  email: string
  name: string
  surname: string
  birth_date: string
  phone: string
  gender: CustomerGender
  created: string
}

export type TenantCustomerDTO = CustomerDTO

// user

export type UserDTO = CustomerDTO

// tags

export enum TagType {
  STRING = 'string',
  NUMBER = 'number',
  EMAIL = 'email'
}

export enum TagRequired {
  REQUIRED = 'required',
  OPTIONAL = 'optional'
}

export type TagDTO = {
  id: string
  name: string
  type: TagType
  required: TagRequired
}

// roles

export type RoleDTO = {
  id: string
  name: string
}

// fields

export enum TenantFieldValue {
  REQUIRED = 'required',
  OPTIONAL = 'optional'
}

export type TenantFieldsDTO = {
  id: string
  fields: {
    name: TenantFieldValue
    surname: TenantFieldValue
    gender: TenantFieldValue
    phone: TenantFieldValue
    birth_date: TenantFieldValue
  }
}

// company

export type CompanyInfoDTO = {
  name: string
  emailAdmin: string
  emailComtact: string
}
