import {
  Button,
  Form,
  Input,
  message,
  Select,
  Skeleton,
  Typography
} from 'antd'
import { Rule } from 'antd/lib/form'
import React, { useCallback, useEffect, useMemo } from 'react'
import AppSpaced from '~component/layout/AppSpaced'
import AppTabbedDetailLayout from '~component/layout/AppTabbedDetailLayout'
import useTenantCustomerState from '~component/screen/tenantCustomer/TenantCustomerDetail.state'
import { PaginationParams } from '~component/widget/AppDataGrid'
import DatePicker from '~component/widget/DatePicker'
import useStrings from '~i18n/useStrings'
import { makeScreenNavigation } from '~navigation/base/makeScreenNavigation'
import { TenantCustomersLink } from '~navigation/links'
import { ScreenPath } from '~navigation/types'
import { CustomerDTO, CustomerGender } from '~service/dto'
import {
  TenantCustomersItem,
  useFetchTenantCustomer,
  useFetchUpdateTenantCustomer
} from '~service/tenantCustomers'
import { FetchState } from '~service/useFetch'

const { Text } = Typography

export type TenantCustomerDetailScreenNavigationParams = {
  id: string
}

export type TenantCustomerDetailScreenNavigationState = {
  data?: TenantCustomersItem
  customersPagination?: PaginationParams
}

export const TenantCustomerDetailPath = `/${ScreenPath.CUSTOMERS}/:id`

const {
  useScreenNavigation,
  useGoToScreen: useGoToTenantCustomerDetailScreen,
  ScreenLink: TenantCustomerDetailLink
} = makeScreenNavigation<
  TenantCustomerDetailScreenNavigationParams,
  TenantCustomerDetailScreenNavigationState
>((params) => `/${ScreenPath.CUSTOMERS}/${params.id}`)

export { useGoToTenantCustomerDetailScreen, TenantCustomerDetailLink }

export default function TenantCustomerDetailScreen() {
  const [{ app: strings }] = useStrings()

  const [customer, { fetch: fetchCustomer }] = useFetchTenantCustomer()
  const [
    updateCustomer,
    { fetch: fetchUpdateCustomer }
  ] = useFetchUpdateTenantCustomer()

  const { navigationParams, navigationState } = useScreenNavigation()
  const [state, dispatch] = useTenantCustomerState(navigationState?.data)

  useEffect(() => {
    // TODO: setSuffix with navigationParams
    fetchCustomer()
  }, [])

  useEffect(() => {
    if (customer.state === FetchState.OK) {
      dispatch({ type: 'init', payload: customer.data })
    }
  }, [customer])

  useEffect(() => {
    if (updateCustomer.state === FetchState.OK) {
      message.success(strings.message.updated)
    }
  }, [updateCustomer])

  const validation = useMemo(() => {
    return {
      requiredEmail: [
        { required: true, message: strings.validation.required },
        { type: 'email', message: strings.validation.email }
      ],
      requiredDate: [
        { required: true, message: strings.validation.required },
        { type: 'date', message: strings.validation.email }
      ],
      required: [{ required: true, message: strings.validation.required }]
    } as { [key: string]: Rule[] }
  }, [strings])

  const handleSubmit = useCallback((values: CustomerDTO) => {
    fetchUpdateCustomer(values)
  }, [])

  return (
    <>
      {!state.title && (
        <AppSpaced>
          <Skeleton active />
        </AppSpaced>
      )}
      {state.title && (
        <AppTabbedDetailLayout
          title={state.title}
          breadcrumbs={[
            <TenantCustomersLink
              pagination={navigationState?.customersPagination}
            />,
            <Text>{state.title}</Text>
          ]}
        >
          <AppTabbedDetailLayout.Tab title={strings.tenantCustomers.tabInfo}>
            {!state.data && <Skeleton active />}
            {state.data && (
              <Form<CustomerDTO>
                onFinish={handleSubmit}
                initialValues={state.data}
                layout="vertical"
                name="login_form"
                wrapperCol={{ span: 8 }}
              >
                <Form.Item
                  label={strings.customers.email}
                  name="email"
                  rules={validation.email}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={strings.customers.name}
                  name="name"
                  rules={validation.required}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={strings.customers.surname}
                  name="surname"
                  rules={validation.required}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={strings.customers.birth_date}
                  name="birth_date"
                  rules={validation.requiredDate}
                  required
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  label={strings.customers.phone}
                  name="phone"
                  rules={validation.required}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={strings.customers.gender}
                  name="gender"
                  rules={validation.required}
                  required
                >
                  <Select>
                    <Select.Option value={CustomerGender.MALE}>
                      {strings.customers.genderMale}
                    </Select.Option>
                    <Select.Option value={CustomerGender.FEMALE}>
                      {strings.customers.genderFemale}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={updateCustomer.state === FetchState.FETCHING}
                  >
                    {strings.global.save}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </AppTabbedDetailLayout.Tab>
        </AppTabbedDetailLayout>
      )}
    </>
  )
}
