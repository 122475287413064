export default function ensureNumber(a: any, def: number) {
  if (!a) {
    return def
  }
  const n = parseInt(a)
  if (isNaN(n)) {
    return def
  }
  return n
}
