import { Card, Tabs } from 'antd'
import React, { useMemo } from 'react'
import { WithChildrenProp } from '~component/types'
import classes from './AppTabbedCard.less'

const { TabPane } = Tabs

export type AppTabbedCardTabProps = {
  title: string
  key: string
  icon: React.ComponentType
} & WithChildrenProp

export type AppTabbedCardProps = {
  title: string
  defaultActiveTab: string
  children:
    | React.ReactElement<AppTabbedCardTabProps>
    | Array<React.ReactElement<AppTabbedCardTabProps>>
}

export default function AppTabbedCard(props: AppTabbedCardProps) {
  const bodyStyle = useMemo<React.CSSProperties>(() => {
    return {
      paddingLeft: 0
    }
  }, [])
  return (
    <Card title={props.title} bodyStyle={bodyStyle} className={classes.root}>
      <Tabs defaultActiveKey={props.defaultActiveTab} tabPosition="left">
        {React.Children.map(props.children, ({ props }) => {
          const { title, key, icon: Icon } = props
          return (
            <TabPane
              tab={
                <>
                  <Icon />
                  {title}
                </>
              }
              key={key}
            >
              {props.children}
            </TabPane>
          )
        })}
      </Tabs>
    </Card>
  )
}

AppTabbedCard.Tab = (_props: AppTabbedCardTabProps) => null
