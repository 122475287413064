import { TenantCustomersItem } from '~service/tenantCustomers'
import { useReducer } from 'react'

type State = {
  data?: Array<TenantCustomersItem>
}

type Action =
  | {
      type: 'init'
      payload: Array<TenantCustomersItem>
    }
  | {
      type: 'remove'
      payload: TenantCustomersItem
    }

const reducer = (state: State, action: Action): State => {
  if (action.type === 'init') {
    return { data: action.payload }
  } else if (action.type === 'remove') {
    const cid = action.payload.id
    const data = state.data!.filter((customer) => customer.id !== cid)
    return { data }
  }

  return state
}

export default function useTenantCustomersState() {
  return useReducer(reducer, {})
}
