import React from 'react'
import one_ignal_src from './OneSignal.png'
import stripe_ignal_src from './Stripe.png'

export const OneSignalIcon = () => {
  return (
    <img
      src={one_ignal_src}
      alt='OneSignal'
      style={{ backgroundColor: 'white' }}
    />
  )
}

export const StripeIcon = () => {
  return <img src={stripe_ignal_src} alt='Stripe' />
}
