import { Breadcrumb, Card, Tabs, Typography } from 'antd'
import React, { useMemo } from 'react'
import AppSpaced, { useSpaceSize } from '~component/layout/AppSpaced'
import { WithChildrenProp } from '~component/types'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import classes from './AppTabbedDetailLayout.less'

export type AppTabbedDetailLayoutBreadcrumb = React.ReactElement

export type AppTabbedDetailLayoutTabProps = {
  title: string
} & WithChildrenProp

export type AppTabbedDetailLayoutProps = {
  title: string
  breadcrumbs?: Array<AppTabbedDetailLayoutBreadcrumb>
  children:
    | React.ReactElement<AppTabbedDetailLayoutTabProps>
    | Array<React.ReactElement<AppTabbedDetailLayoutTabProps>>
}

const { Title } = Typography
const { TabPane } = Tabs

export default function AppTabbedDetailLayout(
  props: AppTabbedDetailLayoutProps
) {
  const space = useSpaceSize()

  const tabBarStyle = useMemo(() => {
    return {
      marginBottom: 0,
      paddingLeft: space,
      paddingRight: space
    } as React.CSSProperties
  }, [space])

  return (
    <>
      <AppBrowserTitle text={props.title} />
      <div className={classes.root}>
        <AppSpaced nb>
          <Breadcrumb>
            {props.breadcrumbs !== undefined &&
              React.Children.map(props.breadcrumbs, (child) => {
                return <Breadcrumb.Item>{child}</Breadcrumb.Item>
              })}
          </Breadcrumb>
          <Title level={2}>{props.title}</Title>
        </AppSpaced>
        <Tabs tabBarStyle={tabBarStyle}>
          {React.Children.map(props.children, ({ props }) => {
            const { title } = props
            return (
              <TabPane tab={title}>
                <AppSpaced className={classes.content}>
                  <Card title={title}>{props.children}</Card>
                </AppSpaced>
              </TabPane>
            )
          })}
        </Tabs>
      </div>
    </>
  )
}

AppTabbedDetailLayout.Tab = (_props: AppTabbedDetailLayoutTabProps) => null
