import {
  TenantCustomerResponse,
  TenantCustomersItem
} from '~service/tenantCustomers'
import { useReducer } from 'react'

type State = { data?: TenantCustomerResponse; title?: string }
type Action = { type: 'init'; payload: TenantCustomerResponse }

const reducer = (state: State, action: Action): State => {
  if (action.type === 'init') {
    return {
      data: action.payload,
      title: `${action.payload.name} ${action.payload.surname}`
    }
  }
  return state
}

export default function useTenantCustomerState(data?: TenantCustomersItem) {
  return useReducer(reducer, {
    title: data ? `${data.name} ${data.surname}` : undefined
  })
}
