import React from 'react'

export default function LoginClaimImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={441} height={317} viewBox="0 0 441 317" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M441 317c-22.723-3.325-44.577-6.192-65.56-8.6l8.297-13.415c-2.797-.825-15.087 6.822-15.087 6.822l10.892-39.001c-14.069 1.354-21.227 41.195-21.227 41.195l-15.716-13.554 7.607 15.192c-64.221-6.623-119.685-9.225-166.337-9.525l7.102-11.482c-2.797-.826-15.088 6.822-15.088 6.822l10.892-39.002c-14.068 1.354-21.227 41.196-21.227 41.196l-15.715-13.554 8.057 16.09c-39.11.474-71.004 2.559-95.622 4.991 7.184-17.904 31.582-34.852 31.582-34.852-18.631 4.549-28.405 12.271-33.513 19.488-.39-22.15 2.236-63.201 21.75-108.572 0 0-38.52 69.917-33.613 116.99l.587 8.358C16.414 306.346 0 310.338 0 310.338L441 317z"
          fill="#3F3D56"
        />
        <path
          d="M234.495 12.697v282.53a12.693 12.693 0 01-3.726 8.971 12.718 12.718 0 01-8.982 3.717H106.988a12.684 12.684 0 01-11.744-7.829 12.662 12.662 0 01-.963-4.859V12.697a12.685 12.685 0 013.721-8.978A12.706 12.706 0 01106.988 0h17.18v2.201a10.45 10.45 0 003.063 7.393 10.47 10.47 0 007.398 3.064h58.388c2.233.004 4.409-.711 6.203-2.04a10.426 10.426 0 004.258-8.417V0h18.309a12.715 12.715 0 018.987 3.718 12.682 12.682 0 013.721 8.98z"
          fill="#3F3D56"
        />
        <path
          d="M126.262 50.125a3.053 3.053 0 003.054-3.051 3.053 3.053 0 00-3.054-3.051 3.052 3.052 0 100 6.102z"
          fill="#F2F2F2"
        />
        <path
          d="M200.25 9.755c-.323.31-.667.6-1.03.863a54.61 54.61 0 0014.879 16.82 54.666 54.666 0 0020.396 9.415v-1.36a53.378 53.378 0 01-19.823-9.283A53.317 53.317 0 01200.25 9.755zM174.158 12.658h-1.431a79.184 79.184 0 0024.297 32.88 79.285 79.285 0 0037.471 16.395V60.58A77.929 77.929 0 01197.977 44.6a77.834 77.834 0 01-23.819-31.941zM147.799 12.658h-1.378c11.53 39.986 46.321 69.752 88.074 74.585v-1.329c-41.029-4.812-75.222-34.01-86.696-73.256z"
          fill="#44F1A6"
        />
        <path
          d="M116.228 69.661h-.795v-.794h-.155v.794h-.795v.156h.795v.794h.155v-.794h.795v-.156zM169.887 29.997h-.795v-.794h-.155v.794h-.795v.155h.795v.795h.155v-.794h.795v-.156zM124.08 101.044h-.794v-.794h-.155v.794h-.796v.155h.796v.794h.155v-.794h.794v-.155zM160.725 77.943h-.795v-.794h-.154v.794h-.796v.155h.796v.794h.154v-.794h.795v-.155zM213.075 92.326h-.795v-.794h-.155v.794h-.795v.156h.795v.794h.155v-.794h.795v-.156z"
          fill="#F2F2F2"
        />
        <path
          d="M203.914 56.227a1.744 1.744 0 10.002-3.488 1.744 1.744 0 00-.002 3.488z"
          fill="#FF6584"
        />
        <path
          d="M141.4 95.368l-.991.153a91.89 91.89 0 01.733 6.001l.999-.087a90.835 90.835 0 00-.741-6.067zM138.691 83.451l-.96.292a84.25 84.25 0 011.54 5.841l.981-.222a89.361 89.361 0 00-1.561-5.91zM134.367 72.023l-.911.418a91.018 91.018 0 012.334 5.575l.937-.353a87.779 87.779 0 00-2.36-5.64zM128.522 61.31l-.847.535a89.63 89.63 0 013.071 5.2l.882-.48a89.02 89.02 0 00-3.106-5.256zM121.254 51.49l-.764.649a89.828 89.828 0 013.761 4.733l.802-.597a87.23 87.23 0 00-3.799-4.786zM112.712 42.746l-.667.745a88.597 88.597 0 014.371 4.18l.72-.702a88.093 88.093 0 00-4.424-4.223zM103.062 35.24l-.563.828a86.502 86.502 0 014.899 3.548l.62-.789a90.37 90.37 0 00-4.956-3.587zM94.28 30.027v1.133a84.628 84.628 0 013.09 1.7l.5-.867a90.844 90.844 0 00-3.59-1.966zM141.173 189.517c.163-.975.312-1.97.443-2.958l.994.132a90.362 90.362 0 01-.448 2.99l-.989-.164zM138.377 201.276l.959.298a92.354 92.354 0 001.617-5.897l-.977-.231a89.631 89.631 0 01-1.599 5.83zM133.996 212.535l.911.427a87.49 87.49 0 002.41-5.616l-.936-.365a85.038 85.038 0 01-2.385 5.554zM128.102 223.098l.841.546a89.38 89.38 0 003.165-5.235l-.875-.49a88.243 88.243 0 01-3.131 5.179zM120.809 232.748l.761.658a93.61 93.61 0 003.855-4.756l-.804-.6a88.339 88.339 0 01-3.812 4.698zM112.27 241.31l.661.754a89.729 89.729 0 004.467-4.181l-.71-.71a92.028 92.028 0 01-4.418 4.137zM102.649 248.629l.555.838a91.127 91.127 0 004.989-3.536l-.606-.8a87.674 87.674 0 01-4.938 3.498zM97.984 252.651a86.496 86.496 0 01-3.703 1.975v-1.125a90.323 90.323 0 003.206-1.717l.497.867zM141.467 107.705l1.003-.018c.018 1.012.02 2.029.004 3.023l-1.003-.015c.016-.983.014-1.989-.004-2.99z"
          fill="#E6E6E6"
        />
        <path
          d="M98.687 131.472h102.61a28.809 28.809 0 0120.359 8.425 28.753 28.753 0 018.433 20.342v3.808h-102.61a28.804 28.804 0 01-20.359-8.426 28.753 28.753 0 01-8.433-20.341v-3.808z"
          fill="#fff"
        />
        <path
          d="M166.785 307.514c-.1-.163-2.461-4.101-3.279-12.279-.751-7.502-.268-20.149 6.296-37.789 12.436-33.418-2.866-60.382-3.022-60.651l.755-.437c.039.068 3.988 6.943 6.32 17.89a77.98 77.98 0 01-3.235 43.502c-12.414 33.361-3.185 49.154-3.09 49.31l-.745.454z"
          fill="#F2F2F2"
        />
        <path
          d="M161.922 194.833a5.669 5.669 0 005.671-5.666 5.669 5.669 0 00-5.671-5.667 5.669 5.669 0 00-5.671 5.667 5.669 5.669 0 005.671 5.666z"
          fill="#44F1A6"
        />
        <path
          d="M179.808 215.755a5.67 5.67 0 005.672-5.667 5.669 5.669 0 00-5.672-5.666 5.669 5.669 0 00-5.671 5.666 5.669 5.669 0 005.671 5.667z"
          fill="#F2F2F2"
        />
        <path
          d="M167.593 229.703a5.67 5.67 0 005.672-5.667 5.669 5.669 0 00-5.672-5.666 5.669 5.669 0 00-5.671 5.666 5.669 5.669 0 005.671 5.667zM182.426 241.471a5.669 5.669 0 005.671-5.666 5.669 5.669 0 00-5.671-5.667 5.669 5.669 0 00-5.671 5.667 5.669 5.669 0 005.671 5.666z"
          fill="#44F1A6"
        />
        <path
          d="M163.231 259.777a5.668 5.668 0 005.671-5.666 5.669 5.669 0 00-5.671-5.666 5.669 5.669 0 00-5.671 5.666 5.668 5.668 0 005.671 5.666zM170.211 307.723s-5.671-13.948 11.342-24.409l-11.342 24.409zM163.236 307.47s-2.581-14.832-22.558-14.705l22.558 14.705z"
          fill="#F2F2F2"
        />
        <path
          opacity={0.2}
          d="M140.658 182.629c19.275 0 34.9-15.612 34.9-34.87 0-19.258-15.625-34.869-34.9-34.869s-34.9 15.611-34.9 34.869 15.625 34.87 34.9 34.87z"
          fill="#44F1A6"
        />
        <path
          opacity={0.2}
          d="M140.658 172.315c13.574 0 24.577-10.994 24.577-24.556 0-13.562-11.003-24.556-24.577-24.556-13.574 0-24.577 10.994-24.577 24.556 0 13.562 11.003 24.556 24.577 24.556z"
          fill="#44F1A6"
        />
        <path
          d="M140.658 163.966c8.959 0 16.221-7.256 16.221-16.207s-7.262-16.207-16.221-16.207-16.221 7.256-16.221 16.207 7.262 16.207 16.221 16.207z"
          fill="#44F1A6"
        />
        <path
          d="M143.607 144.264a2.942 2.942 0 00-2.771-2.942 2.953 2.953 0 00-2.572 1.22 2.942 2.942 0 00.531 4.005l-1.086 7.597h5.898l-1.086-7.597c.34-.276.614-.624.801-1.019.188-.395.286-.827.285-1.264z"
          fill="#fff"
        />
        <path
          d="M303.676 23.232s-9.729-8.715-22.813 7.71c-13.085 16.424-33.549 24.469-38.246 30.837 0 0 19.458-8.044 26.168-11.396 6.71-3.352 6.374-2.682 6.374-2.682s-9.058 6.369-10.735 12.738c-1.678 6.368-.336 11.732-3.355 18.1-3.02 6.37 57.704 4.358 57.704 4.358s6.039-10.391 4.361-21.118c-1.677-10.726-.671-37.206-19.458-38.547z"
          fill="#2F2E41"
        />
        <path
          d="M285.274 50.649s2.115 14.796-6.952 18.118c-9.066 3.321-5.742 7.85-5.742 7.85l13.6 6.644 14.507-4.53 4.836-7.247s-7.858-1.811-5.742-7.247c2.115-5.435 2.72-7.247 2.72-7.247l-17.227-6.341z"
          fill="#FFB9B9"
        />
        <path
          opacity={0.1}
          d="M285.274 50.649s2.115 14.796-6.952 18.118c-9.066 3.321-5.742 7.85-5.742 7.85l13.6 6.644 14.507-4.53 4.836-7.247s-7.858-1.811-5.742-7.247c2.115-5.435 2.72-7.247 2.72-7.247l-17.227-6.341z"
          fill="#000"
        />
        <path
          d="M323.355 92.924s-2.116 27.781-1.512 28.989c.605 1.208 0 43.181 0 43.181s4.232 22.647-1.209 23.855c-5.44 1.208-4.231-25.365-4.231-25.365l-5.44-35.028.605-34.726 11.787-.906z"
          fill="#FFB9B9"
        />
        <path
          opacity={0.1}
          d="M323.355 92.924s-2.116 27.781-1.512 28.989c.605 1.208 0 43.181 0 43.181s4.232 22.647-1.209 23.855c-5.44 1.208-4.231-25.365-4.231-25.365l-5.44-35.028.605-34.726 11.787-.906z"
          fill="#000"
        />
        <path
          d="M264.42 176.568s-9.974 37.746-9.671 56.166c.302 18.42 4.231 44.087 4.231 44.087s.604 11.173-.302 12.381c-.907 1.207 4.533 6.341 4.533 6.341l5.742-3.322 2.116-2.416v-2.113s-3.325-10.267-.605-18.42c2.721-8.153 4.232-28.687 1.512-35.028l18.738-55.562-26.294-2.114z"
          fill="#FFB9B9"
        />
        <path
          d="M263.815 290.711s-3.626-4.529-6.044-3.019c-2.418 1.51-6.347 11.776-6.347 11.776s-13.6 12.985-3.929 13.589c9.671.604 13.903-2.718 15.112-5.134 1.208-2.415 12.391-8.757 12.391-8.757s-1.209-11.474-3.022-11.776c-1.814-.302-4.534 4.529-4.534 4.529l-3.627-1.208z"
          fill="#2F2E41"
        />
        <path
          d="M297.363 178.984v37.444c.035 4.543.338 9.081.907 13.588.906 6.644-5.138 56.166-5.138 56.166l.604 8.757 7.556-.604.906-7.549 10.881-33.216s3.626-20.232 1.813-27.781c-1.813-7.549 9.369-55.864 9.369-55.864l-26.898 9.059z"
          fill="#FFB9B9"
        />
        <path
          d="M291.318 292.825l2.239-.486s1.69-3.741 3.806-2.835a22.825 22.825 0 014.207 2.518l.931 1.709s2.115 4.53 4.835 7.549c2.72 3.02 5.743 9.663 1.209 10.267-4.533.604-10.578.906-12.391-.604-1.813-1.51-.605-3.925-2.418-4.529-1.813-.604-3.324-2.416-3.022-3.02.302-.604.604-10.569.604-10.569z"
          fill="#2F2E41"
        />
        <path
          d="M297.363 61.05c7.418 0 13.432-6.009 13.432-13.42 0-7.413-6.014-13.421-13.432-13.421-7.419 0-13.432 6.008-13.432 13.42s6.013 13.42 13.432 13.42z"
          fill="#FFB9B9"
        />
        <path
          d="M271.976 71.183l3.992-1.393s-1.575 2.6 6.888 4.11c8.462 1.51 17.226 2.044 21.155-2.903 0 0 1.512-.72 3.628 1.393 2.115 2.114 3.324 1.51 3.324 1.51l-1.511 7.851-3.022 14.495-3.929 10.87-11.183-2.415-12.089-8.757-4.533-11.777v-10.87l-2.72-2.114z"
          fill="#44F1A6"
        />
        <path
          d="M308.848 75.41l3.022-2.114s10.276 1.812 10.88 6.643l-7.858 15.099a17.175 17.175 0 01-.302 12.984c-3.022 6.945-2.72 8.455-2.72 8.455l-2.116 9.059-38.383 3.02s-1.511-3.624-2.115-4.228c-.605-.604-.605-2.717 0-2.717.604 0 0-.906-.605-1.812-.604-.906-.907-1.208 0-2.416s-1.511-12.078-1.511-12.078V93.528l-10.276-13.589s3.627-5.435 5.44-6.039c1.814-.604 10.44-2.986 10.44-2.986l1.952 2.872 2.72 21.856 3.929 13.286 16.543-2.808 6.426-10.78 4.231-12.683.303-7.247z"
          fill="#44F1A6"
        />
        <path
          d="M320.332 78.128l2.418 1.811s2.116 16.91 1.511 18.118c-.604 1.208-11.182 2.416-11.484 1.812-.303-.604 7.555-21.741 7.555-21.741z"
          fill="#44F1A6"
        />
        <path
          d="M270.464 69.673l3.023 19.93s-2.72 29.592-2.116 35.631l.605 1.812s-1.209-.604-1.512.604c-.302 1.208 0 5.133 0 5.133s-1.813 1.812-2.417 11.777c-.605 9.965-7.858 33.82-5.138 34.726 2.72.906 19.04 5.436 27.805 4.53 8.764-.906 40.196-11.475 39.894-14.193-.302-2.717-15.414-41.067-15.414-41.067s-2.72-4.53-2.72-5.133c0-.604 3.627-3.02 2.72-5.436-.906-2.416-5.742-27.78-5.742-27.78l3.325-18.119-4.534-1.207-3.324 17.513s-12.996 6.342-26.597.604L275.3 68.465l-4.836 1.208zM285.006 37.998s22.365 15.4 28.409 8.455c6.045-6.946-10.578-12.985-10.578-12.985l-13.902-1.51-3.929 6.04z"
          fill="#2F2E41"
        />
        <path
          d="M250.256 201.825a11.798 11.798 0 0015.536-1.28 11.774 11.774 0 00.686-15.56 11.803 11.803 0 00-15.363-2.641 11.78 11.78 0 00-4.558 14.894l-30.845 25.661-4.107-4.871-1.846 1.234-2.053-2.436 1.687-1.42-2.053-2.435-2.625 2.209 1.264 1.499-2.813 2.367-1.263-1.498-4.125 3.472 1.896 2.248 1.499-1.263 2.054 2.435-1.875 1.579 3.791 4.495-4.499 3.788 4.581 5.432 45.031-37.909zm7.702-17.213a7.805 7.805 0 016.771 5.407 7.793 7.793 0 01-6.57 10.104 7.805 7.805 0 01-7.697-3.983 7.79 7.79 0 011.805-9.723 7.805 7.805 0 015.691-1.805z"
          fill="#44F1A6"
        />
        <path
          d="M254.749 96.548s2.115 27.78 1.511 28.988c-.605 1.208 0 43.181 0 43.181s-4.231 22.648 1.209 23.856c5.44 1.207 4.231-25.365 4.231-25.365l5.44-35.028-.605-34.727-11.786-.905z"
          fill="#FFB9B9"
        />
        <path
          d="M259.282 79.034l-2.418.905s-4.533 19.024-3.324 19.024c1.209 0 15.716 1.812 15.716 1.208 0-.604-2.721-15.4-2.721-15.4l-7.253-5.737z"
          fill="#44F1A6"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h441v317H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
