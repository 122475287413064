import { message } from 'antd'
import { StripeAuth } from '~component/widget/StripeAuthPopUp'
import { getStrings } from '~i18n/useStrings'
import { useSafeState } from '~lib/base/useSafeState'
import { clearSession } from '~lib/useSession'
import { RoleDTO, TagDTO, TenantDTO, TenantFieldsDTO } from '~service/dto'
import {
  FetchHookLeft,
  FetchMethod,
  FetchState,
  makeFetch,
  useFetch
} from '~service/useFetch'

// list

export type TenantsResponseItem = {
  id: string
  name: string
  contact: string
  admin: string
  oneSignal: boolean
  stripe: boolean
  created: string
}

export type TenantsResponse = Array<TenantsResponseItem>

export const useFetchTenants = () =>
  useFetch<TenantsResponse>(FetchMethod.GET, '/api/v1/tenants')

// detail

export type TenantResponse = TenantDTO

export const encodeFetchTenantParams = (id: string) => `/${id}`

export const useFetchTenant = () =>
  useFetch<TenantResponse>(FetchMethod.GET, '/api/v1/tenant')

export const useFetchTenantById = (id: string) =>
  useFetch<TenantResponse>(FetchMethod.GET, `/api/v1/tenant/${id}`)

// add

export type AddTenantResponse = {
  tenant: TenantDTO
  clientId: string
  clientSecret: string
}

export type AddTenantBody = {
  id?: string
  name: string
  contact: string
  email: string
  password: string
  tags?: Array<TagDTO>
}

export const useFetchAddTenant = () =>
  useFetch<AddTenantResponse, AddTenantBody>(FetchMethod.POST, '/api/v1/tenant')

// update

export type UpdateTentantResponse = TenantDTO
export type UpdateTentantBody = TenantDTO

export const encodeUpdateTenantParams = (tenant: TenantDTO) => `/${tenant.id}`

export const useFetchUpdateTenant = () =>
  useFetch<UpdateTentantResponse, UpdateTentantBody>(
    FetchMethod.PUT,
    '/api/v1/tenant'
  )

// connect (one signal, stripe)

export type ConnectTenantOneSignalResponse = {
  basic_auth_key: string
  id: string
}

export type ConnectTenantStripeResponse = {
  stripe_user_id: string
  id: string
}

export type ConnectTenantResponse = {
  tenantId: string
  clientId: string
  clientSecret: string
  oneSignalSecret?: string
  stripeUserId?: string
}

export const useFetchConnectTenant = (): [
  FetchHookLeft<ConnectTenantResponse>,
  (
    createResponse: AddTenantResponse,
    oneSignal: boolean,
    stripeAuth: StripeAuth | undefined
  ) => void
] => {
  const [fetchState, setFetchState] = useSafeState<
    FetchHookLeft<ConnectTenantResponse>
  >({
    state: FetchState.IDLE
  })

  const fetch = async (
    createResponse: AddTenantResponse,
    oneSignal: boolean,
    stripeAuth: StripeAuth | undefined
  ) => {
    if (fetchState.state !== FetchState.FETCHING) {
      setFetchState({ state: FetchState.FETCHING })
    }

    let oneSignalSecret: string | undefined
    let stripeUserId: string | undefined

    // TODO: Promise.all

    if (oneSignal) {
      const res = await makeFetch<ConnectTenantOneSignalResponse>(
        FetchMethod.POST,
        `/api/v1/onesignalconnect`,
        {
          id: createResponse.tenant.id
        }
      )

      if (!res.error) {
        oneSignalSecret = res.data.basic_auth_key
      } else {
        if (res.status === 401) {
          message.error(getStrings().app.error.sessionExpired)
          // kick out
          return clearSession()
        }

        return setFetchState({
          state: FetchState.ERROR,
          ...res
        })
      }
    }

    if (stripeAuth) {
      const res = await makeFetch<ConnectTenantStripeResponse>(
        FetchMethod.GET,
        `/api/v1/stripeaccesstoken?code=${stripeAuth.code}&state=${stripeAuth.state}`,
        undefined
      )
      if (!res.error) {
        stripeUserId = res.data.stripe_user_id
      } else {
        if (res.status === 401) {
          message.error(getStrings().app.error.sessionExpired)
          // kick out
          return clearSession()
        }

        return setFetchState({
          state: FetchState.ERROR,
          ...res
        })
      }
    }

    const data: ConnectTenantResponse = {
      tenantId: createResponse.tenant.id,
      clientId: createResponse.clientId,
      clientSecret: createResponse.clientSecret,
      oneSignalSecret,
      stripeUserId
    }

    setFetchState({
      state: FetchState.OK,
      data,
      fromCache: false
    })
  }

  return [fetchState, fetch]
}

// fields

export type UpdateTenantResponse = TenantDTO
export type UpdateTenantBody = TenantFieldsDTO

export const useFetchUpdateTenantFields = () =>
  useFetch<UpdateTenantResponse, UpdateTenantBody>(
    FetchMethod.POST,
    '/api/v1/mandatory'
  )

// tags

export type AddTenantTagResponse = TenantDTO
export type AddTenantTagBody = TagDTO

export const useFetchAddTenantTag = () =>
  useFetch<AddTenantTagResponse, AddTenantTagBody>(
    FetchMethod.POST,
    '/api/v1/tag'
  )

// roles

export type AddTenantRoleResponse = TenantDTO
export type AddTenantRoleBody = RoleDTO

export const useFetchAddTenantRole = () =>
  useFetch<TenantDTO, AddTenantRoleBody>(FetchMethod.POST, '/api/v1/role')

// stripe link

export type TenantStripeLinkResponse = {
  link: string
}

export const useFetchStripeLink = (id: string) =>
  useFetch<TenantStripeLinkResponse>(
    FetchMethod.GET,
    `/api/v1/stripelink/${id}`
  )
