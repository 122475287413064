import { Menu } from 'antd'
import React from 'react'

export type AdminLeftItem = {
  key: string
  title: string
  icon: React.ReactNode
}

export type AdminLeftProps = {
  items: Array<AdminLeftItem>
  defaultSelectedKeys: Array<string>
  onClick: (item: AdminLeftItem) => void
}

export default function AdminLeft(props: AdminLeftProps) {
  return (
    <Menu mode="inline" defaultSelectedKeys={props.defaultSelectedKeys}>
      {props.items.map((item) => {
        const { key, title, icon } = item
        return (
          <Menu.Item key={key} icon={icon} onClick={() => props.onClick(item)}>
            {title}
          </Menu.Item>
        )
      })}
    </Menu>
  )
}
