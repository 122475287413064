// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3DTHDM7XtwRoJNsKiQh63i {\n  padding-top: 0;\n  padding-left: 0;\n  padding-right: 0;\n}\n._1dVglqFIUAqsHSpqmfMo3e {\n  min-height: 300px;\n}\n._1BeHRFMqqyMmNU27HImU2r {\n  color: green;\n}\n._3MA_ZaH4bm08T6Zm3Bv2xC {\n  color: red;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"header": "_3DTHDM7XtwRoJNsKiQh63i",
	"card": "_1dVglqFIUAqsHSpqmfMo3e",
	"greenIcon": "_1BeHRFMqqyMmNU27HImU2r",
	"redIcon": "_3MA_ZaH4bm08T6Zm3Bv2xC"
};
module.exports = exports;
