import { useEffect, useRef, useState } from 'react'

export function useSafeState<T>(initialState: T): [T, (next: T) => void] {
  const isUnmounted = useRef(false)

  const [state, setState] = useState<T>(initialState)

  useEffect(() => {
    isUnmounted.current = false

    return () => {
      isUnmounted.current = true
    }
  }, [])

  return [
    state,
    (next: T) => {
      if (!isUnmounted.current) {
        setState(next)
      }
    }
  ]
}
