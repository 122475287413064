import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Dropdown, Menu, Modal, Row, Space } from 'antd'
import React, { useCallback, useState } from 'react'
import useStrings from '~i18n/useStrings'

export type AdminTopProps = {
  user: { name: string; src?: string }
  onLogout: () => void
}

export default function AdminTop(props: AdminTopProps) {
  const [showsModal, setShowsModal] = useState(false)
  const [{ app: strings }] = useStrings()

  const Overlay = useCallback(() => {
    return (
      <Menu>
        <Menu.Item onClick={() => setShowsModal(true)}>
          {strings.adminHeader.logout}
        </Menu.Item>
      </Menu>
    )
  }, [strings])

  const handleOk = useCallback(() => {
    setShowsModal(false)
    setTimeout(props.onLogout, 216)
  }, [props.onLogout])

  const handleCancel = useCallback(() => setShowsModal(false), [])

  return (
    <>
      <Row justify="end">
        <Col>
          <Dropdown overlay={Overlay}>
            <Button
              color="black"
              type="text"
              onClick={(ev) => ev.preventDefault()}
            >
              <Space>
                {props.user.src === undefined && (
                  <Avatar size="small" icon={<UserOutlined />} />
                )}
                {props.user.src !== undefined && (
                  <Avatar size="small" src={props.user.src} />
                )}
                <span>{props.user.name}</span>
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Modal visible={showsModal} onOk={handleOk} onCancel={handleCancel}>
        <p>{strings.adminHeader.logoutConfirm}</p>
      </Modal>
    </>
  )
}
