import { ContactsOutlined, ProfileOutlined } from '@ant-design/icons'
import React, { useCallback } from 'react'
import VYouLogo from '~component/image/VYouLogo'
import AppLTCLayout from '~component/layout/AppLTCLayout'
import { useGoToMyCompany } from '~component/screen/myCompany/MyCompany/screen'
import { WithChildrenProp } from '~component/types'
import AdminLeft from '~component/widget/AdminLeft'
import AdminTop from '~component/widget/AdminTop'
import useStrings from '~i18n/useStrings'
import { clearSession } from '~lib/useSession'
import useUserData from '~lib/useUserData'
import useNavigation from '~navigation/useNavigation'

export type AdminTemplateProps = { god?: boolean } & WithChildrenProp

const CUSTOMERS_KEY = 'customers'
const MY_COMPANY_KEY = 'my_company'

export default function AdminTemplate(props: AdminTemplateProps) {
  const [{ app: strings }] = useStrings()

  const [navState] = useNavigation()
  const selected = navState.underMyCompany ? MY_COMPANY_KEY : CUSTOMERS_KEY

  const Left = useCallback(() => {
    const [, nav] = useNavigation()
    const goToMyCompany = useGoToMyCompany()
    return (
      <AdminLeft
        items={[
          {
            key: CUSTOMERS_KEY,
            title: strings.adminLeftMenu.customers,
            icon: <ContactsOutlined />
          },
          {
            key: MY_COMPANY_KEY,
            title: strings.adminLeftMenu.myCompany,
            icon: <ProfileOutlined />
          }
        ]}
        defaultSelectedKeys={[selected]}
        onClick={({ key }) => {
          if (key === CUSTOMERS_KEY) nav.goToCustomers()
          else if (key === MY_COMPANY_KEY) goToMyCompany({})
        }}
      />
    )
  }, [strings])

  const Top = useCallback(() => {
    const [userData] = useUserData()
    if (userData === null) {
      return <div />
    }

    return (
      <AdminTop
        user={{ name: `${userData.name} ${userData.surname}` }}
        onLogout={() => {
          clearSession()
        }}
      />
    )
  }, [strings])

  return (
    <AppLTCLayout left={Left} top={Top} logo={VYouLogo}>
      {props.children}
    </AppLTCLayout>
  )
}
