import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import MyCompanyScreen, {
  MyCompanyPath
} from '~component/screen/myCompany/MyCompany/screen'
import TenantCustomerDetailScreen, {
  TenantCustomerDetailPath
} from '~component/screen/tenantCustomer/TenantCustomerDetail'
import TenantCustomersScreen, {
  TenantCustomersPagedPath,
  TenantCustomersPath
} from '~component/screen/tenantCustomer/TenantCustomers'
import AdminTemplate from '~component/template/AdminTemplate'
import { ScreenPath } from '~navigation/types'

export default function AdminNavigation() {
  return (
    <Router>
      <AdminTemplate>
        <Switch>
          <Route exact path={TenantCustomersPath}>
            <TenantCustomersScreen />
          </Route>
          <Route exact path={TenantCustomersPagedPath}>
            <TenantCustomersScreen />
          </Route>
          <Route exact path={TenantCustomerDetailPath}>
            <TenantCustomerDetailScreen />
          </Route>
          <Route exact path={MyCompanyPath}>
            <MyCompanyScreen />
          </Route>
          <Route path="*">
            <Redirect to={`/${ScreenPath.CUSTOMERS}`} />
          </Route>
        </Switch>
      </AdminTemplate>
    </Router>
  )
}
