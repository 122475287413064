import { LogoutOutlined } from '@ant-design/icons'
import { Button, Layout, Modal } from 'antd'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback } from 'react'
import { WithChildrenProp } from '~component/types'
import AdminContent from '~component/widget/AdminContent'
import AdminMenu from '~component/widget/AdminMenu'
import Logo from '~component/widget/Logo'
import useStrings from '~i18n/useStrings'
import { clearSession } from '~lib/useSession'
import styles from './AdminLayout.less'

// TODO: deprecated

const { Header, Content } = Layout

export default function AdminLayout(props: WithChildrenProp) {
  const [{ app: strings }] = useStrings()

  const handleLogout = useCallback(() => {
    Modal.confirm({
      content: strings.menu.logoutConfirm,
      okText: strings.global.ok,
      cancelText: strings.global.cancel,
      onOk: () => {
        clearSession()
      }
    })
  }, [strings])

  return (
    <Layout className={styles.root}>
      <Header className={styles.layoutHeader}>
        <AdminContent>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <Logo className={styles.logo} />
              <AdminMenu />
            </div>
            <div className={styles.headerRight}>
              <Button
                onClick={handleLogout}
                shape="circle"
                icon={<LogoutOutlined />}
              />
            </div>
          </div>
        </AdminContent>
      </Header>
      <Content className={styles.layoutContent}>
        <AdminContent>
          <AnimatePresence>{props.children}</AnimatePresence>
        </AdminContent>
      </Content>
    </Layout>
  )
}
