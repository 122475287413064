import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Skeleton
} from 'antd'
import { Rule } from 'antd/lib/form'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react'
import { useLocation } from 'react-router-dom'
import MotionContent from '~component/widget/MotionContent'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import useStrings, { getStrings } from '~i18n/useStrings'
import makeFuzzySearch, { FuzzySearch } from '~lib/makeFuzzySearch'
import {
  AssignTenantBody,
  useFetchAssignTenantToCustomer
} from '~service/customers'
import { CustomerDTO, TagType, TenantDTO } from '~service/dto'
import {
  encodeFetchTenantParams,
  TenantsResponseItem,
  useFetchTenant,
  useFetchTenants
} from '~service/tenants'
import { FetchState } from '~service/useFetch'
import styles from './AssignTenantToCustomer.less'

const LABEL_COL_SPAN = 4
const WRAPPER_COL_SPAN = 12

const CUSTOMER_ROLE = 'CUSTOMER'

export default function AssignTenantToCustomerScreen() {
  const { state: customer } = useLocation<CustomerDTO>()

  const [{ app: strings }] = useStrings()
  const [form] = Form.useForm()

  // fetch

  const [tenants, { fetch: fetchTenants }] = useFetchTenants()
  const [
    tenant,
    { fetch: fetchTenant, setSuffix: setFetchTenantSuffix }
  ] = useFetchTenant()

  const [
    assignTenant,
    { fetch: fetchAssignTenant }
  ] = useFetchAssignTenantToCustomer()

  // state

  const newAfterSubmitRef = useRef<boolean>(false)

  type AutoCompletePayload = {
    key: string
    value: string
    label: string
    tenant: TenantsResponseItem
  }
  type State = {
    inited: boolean
    fuzzySearch: FuzzySearch<TenantsResponseItem>
    autoComplete: Array<AutoCompletePayload>
    tenant?: TenantDTO
  }
  type Action =
    | { type: 'init'; payload: Array<TenantsResponseItem> }
    | { type: 'tenantSearchResults'; payload: Array<TenantsResponseItem> }
    | { type: 'tenantSelect'; payload: TenantDTO }
    | { type: 'reset' }

  const [state, dispatch] = useReducer(
    (state: State, action: Action): State => {
      if (action.type === 'init') {
        state.fuzzySearch.setData(action.payload)
        return { ...state, inited: true }
      } else if (action.type === 'tenantSearchResults') {
        const autoComplete = action.payload.map((tenant) => {
          return {
            key: tenant.id,
            label: tenant.name,
            value: tenant.name,
            tenant
          }
        })
        return { ...state, tenant: undefined, autoComplete }
      } else if (action.type === 'tenantSelect') {
        return { ...state, tenant: action.payload }
      } else if (action.type === 'reset') {
        return {
          inited: true,
          fuzzySearch: state.fuzzySearch,
          autoComplete: []
        }
      }
      return state
    },
    {
      inited: false,
      autoComplete: [],
      fuzzySearch: makeFuzzySearch<TenantsResponseItem>(['name'])
    }
  )

  // validation

  const validationRules: {
    required: Rule
    number: Rule
    email: Rule
  } = useMemo(() => {
    return {
      required: { required: true, message: strings.validation.required },
      number: { type: 'number', message: strings.validation.number },
      email: { type: 'email', message: strings.validation.email }
    }
  }, [strings])

  // flow

  useEffect(() => {
    if (tenants.state === FetchState.IDLE) {
      fetchTenants()
    } else if (tenants.state === FetchState.OK) {
      dispatch({ type: 'init', payload: tenants.data })
    }
  }, [tenants])

  useEffect(() => {
    if (tenant.state === FetchState.OK) {
      dispatch({ type: 'tenantSelect', payload: tenant.data })
    }
  }, [tenant])

  // handles

  const handleTenantSearch = useCallback((q: string) => {
    dispatch({
      type: 'tenantSearchResults',
      payload: state.fuzzySearch.query(q)
    })
  }, [])

  const handleTenantSelect = useCallback(
    (_id: string, payload: AutoCompletePayload) => {
      setFetchTenantSuffix(encodeFetchTenantParams(payload.tenant.id))
      fetchTenant()
    },
    []
  )

  type Data = {
    query: string
    newAfterSubmit: boolean
    roles: Array<string>
    [key: string]: any
  }

  const handleSubmit = useCallback(
    (data: Data) => {
      if (!state.tenant) {
        return
      }

      const { query, newAfterSubmit, roles, ...rest } = data

      const assign: AssignTenantBody = {
        email: customer.email,
        tenantId: state.tenant.id,
        roles: roles,
        tags: []
      }
      const tags: any = {}
      Object.keys(rest).forEach((key: string) => {
        const value = rest[key]
        tags[key] = `${value === undefined ? '' : value}`
      })
      assign.tags = tags

      newAfterSubmitRef.current = data.newAfterSubmit
      fetchAssignTenant(assign)
    },
    [state.tenant]
  )

  useEffect(() => {
    if (assignTenant.state === FetchState.OK) {
      const { app: strings } = getStrings()
      message.success(strings.assignTenantToCustomer.assignSuccess)

      if (newAfterSubmitRef.current) {
        form.resetFields()
        dispatch({ type: 'reset' })
      } else {
        window.history.back()
      }
    }
  }, [assignTenant])

  const title =
    `${strings.assignTenantToCustomer.title} ` +
    `${customer.name}${customer.surname ? ` ${customer.surname}` : ''}`

  return (
    <>
      <AppBrowserTitle text={title} />
      <MotionContent>
        <PageHeader
          className={styles.header}
          onBack={() => window.history.back()}
          title={title}
        />
        <Card>
          {!state.inited && <Skeleton active />}
          {state.inited && (
            <Form
              form={form}
              autoComplete="off"
              initialValues={{ roles: [CUSTOMER_ROLE] }}
              layout="horizontal"
              labelCol={{ span: LABEL_COL_SPAN }}
              wrapperCol={{ span: WRAPPER_COL_SPAN }}
              onFinish={handleSubmit as any}
              name="new_tenant"
            >
              <Form.Item
                label={strings.assignTenantToCustomer.tenant}
                name="query"
              >
                <AutoComplete
                  options={state.autoComplete}
                  onChange={handleTenantSearch}
                  onSelect={handleTenantSelect as any}
                >
                  <Input.Search
                    placeholder={strings.assignTenantToCustomer.searchTenant}
                  />
                </AutoComplete>
              </Form.Item>
              {state.tenant !== undefined && (
                <>
                  {/* <Form.Item wrapperCol={{ offset: LABEL_COL_SPAN }}>
                    <Typography.Title level={4}>
                      {strings.editCustomer.tagsHint}
                    </Typography.Title>
                  </Form.Item>*/}
                  {state.tenant.tags.map((tag, idx) => {
                    const rules: Array<Rule> = []
                    if (tag.required === 'required') {
                      rules.push(validationRules.required)
                    }

                    const isNumber = tag.type === TagType.NUMBER

                    if (tag.type === TagType.EMAIL) {
                      rules.push(validationRules.email)
                    } else if (isNumber) {
                      rules.push(validationRules.number)
                    }

                    return (
                      <Form.Item
                        key={`tag_${state.tenant!.id}_${idx}`}
                        rules={rules}
                        label={tag.name}
                        name={tag.name}
                      >
                        {!isNumber ? (
                          <Input autoComplete="off" />
                        ) : (
                          <InputNumber autoComplete="off" />
                        )}
                      </Form.Item>
                    )
                  })}
                  <Form.Item
                    label={strings.assignTenantToCustomer.roles}
                    name="roles"
                  >
                    <Checkbox.Group
                      options={state.tenant!.allowed_roles.map((role) => {
                        return {
                          value: role,
                          label: role,
                          disabled: role === CUSTOMER_ROLE
                        }
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{ offset: LABEL_COL_SPAN }}
                    valuePropName="checked"
                    name="newAfterSubmit"
                  >
                    <Checkbox>
                      {strings.assignTenantToCustomer.submitHint}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: LABEL_COL_SPAN }}>
                    <Button type="primary" htmlType="submit" loading={false}>
                      {strings.assignTenantToCustomer.submit}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form>
          )}
        </Card>
      </MotionContent>
    </>
  )
}
