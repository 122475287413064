// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fyo24I344-WQE9lmoeihX {\n  padding-top: 0;\n  padding-left: 0;\n  padding-right: 0;\n}\n.QJx43KaYgE8o3yqAXhsFv {\n  display: flex;\n  flex-direction: row;\n}\n._1FNcoxWkrmeINTRzdWg-45 {\n  width: 250px;\n  height: 260px;\n}\n.LS0FX6zpAeW4s2IgtSwBt {\n  height: 260px;\n}\n.KFNqpw5Jzl6095Mo73Yus {\n  flex: 1;\n  margin-left: 20px;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"header": "fyo24I344-WQE9lmoeihX",
	"columns": "QJx43KaYgE8o3yqAXhsFv",
	"steps": "_1FNcoxWkrmeINTRzdWg-45",
	"divider": "LS0FX6zpAeW4s2IgtSwBt",
	"content": "KFNqpw5Jzl6095Mo73Yus"
};
module.exports = exports;
