// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._323W3B52l6dGlGLsVwZaHY {\n  background-color: #edecf0;\n  min-height: 100vh;\n}\n._31vsIFXXk-r3pcUcfzZzvC {\n  height: 66px;\n  border-bottom: 1px solid #dddcdf;\n  background-color: white;\n  margin: 0;\n  padding: 0;\n  position: fixed;\n  z-index: 1;\n  width: 100%;\n}\n._1RbKQkgCNpgqlGXJ89l5hb {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n}\n._3rD34rJAKZZxqEQvzcRiRq {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.bDEkg9ZondlrGZJ9NwnUa {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n._2TK0jkP9mvPYILKIBcL8Qs {\n  height: 30px;\n  width: auto;\n  margin-right: 20px;\n}\n._1EEfktJq8cISxCS7EKBF2F {\n  margin-top: 64px;\n  padding-top: 20px;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"root": "_323W3B52l6dGlGLsVwZaHY",
	"layoutHeader": "_31vsIFXXk-r3pcUcfzZzvC",
	"header": "_1RbKQkgCNpgqlGXJ89l5hb",
	"headerLeft": "_3rD34rJAKZZxqEQvzcRiRq",
	"headerRight": "bDEkg9ZondlrGZJ9NwnUa",
	"logo": "_2TK0jkP9mvPYILKIBcL8Qs",
	"layoutContent": "_1EEfktJq8cISxCS7EKBF2F"
};
module.exports = exports;
