export enum ScreenPath {
  // auth
  LOGIN = '',
  LOGIN_GOD = 'god',
  // signed
  TENANTS = 'tenants',
  TENANTS_ADD_NEW = 'new',
  CUSTOMERS = 'customers',
  MY_COMPANY = 'my_company',
  CUSTOMERS_ASSIGN_TENANT = 'assign_tenant'
}
