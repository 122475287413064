// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1d04wZY-Nyci7bPNW1jbWP {\n  padding-top: 0;\n  padding-left: 0;\n  padding-right: 0;\n}\n._3y0Afup0lwmw0lqXIQiWET {\n  display: flex;\n  flex-direction: row;\n}\n._33DsuXNhYcHWg-t8IpEsoH {\n  width: 250px;\n  height: 260px;\n}\n._22w54R8hoK26bUquR5Cgr6 {\n  height: 260px;\n}\n.NzUeIZTUem1OQdjoJ2uhz {\n  flex: 1;\n  margin-left: 20px;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"header": "_1d04wZY-Nyci7bPNW1jbWP",
	"columns": "_3y0Afup0lwmw0lqXIQiWET",
	"steps": "_33DsuXNhYcHWg-t8IpEsoH",
	"divider": "_22w54R8hoK26bUquR5Cgr6",
	"content": "NzUeIZTUem1OQdjoJ2uhz"
};
module.exports = exports;
