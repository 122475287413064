// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1taTrvMROaiGIuLq3rH_XG {\n  height: 100vh;\n  display: flex;\n  flex-direction: row;\n  background: #f4f6f8;\n}\n._2KSnrHxporqy3KJ1EfIqyk {\n  background: #ffe7ba;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 500px;\n}\n._1I9Iu5YPgeJR11UxhclWOa {\n  width: 300px;\n  margin-bottom: 40px;\n}\n.rad4AcLXgLObSJmqvCY8W {\n  font-size: 1.2em;\n}\n._5i7KzL4g8X1wUK841WEwD {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n._15UOLo1AlFBXBnGSYiuLKe {\n  width: 450px;\n}\n._1-P9cnatjySmuM8hM2Uku4 {\n  width: 100%;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"root": "_1taTrvMROaiGIuLq3rH_XG",
	"left": "_2KSnrHxporqy3KJ1EfIqyk",
	"leftTextBlock": "_1I9Iu5YPgeJR11UxhclWOa",
	"leftTextClaimFont": "rad4AcLXgLObSJmqvCY8W",
	"right": "_5i7KzL4g8X1wUK841WEwD",
	"rightForm": "_15UOLo1AlFBXBnGSYiuLKe",
	"rightFormSubmitButton": "_1-P9cnatjySmuM8hM2Uku4"
};
module.exports = exports;
