import { FetchMethod, useFetch } from '~service/useFetch'
import { AuthDTO } from '~service/dto'

export type AuthResponse = AuthDTO

export type AuthBody = {
  username: string
  password: string
}

export const useFetchLogin = () =>
  useFetch<AuthResponse, AuthBody>(FetchMethod.POST, '/auth')
