import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Space, Typography } from 'antd'
import { Rule } from 'antd/lib/form'
import React, { useCallback, useEffect, useMemo } from 'react'
import LoginClaimImage from '~component/image/LoginClaimImage'
import VYouLogo from '~component/image/VYouLogo'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import useStrings from '~i18n/useStrings'
import { nextSession } from '~lib/useSession'
import { useFetchLogin } from '~service/auth'
import { FetchState } from '~service/useFetch'
import classes from './AppLoginLayout.less'

export type AppLoginLayoutProps = { god?: boolean }

const { Title, Text } = Typography

export default function AppLoginLayout(props: AppLoginLayoutProps) {
  const [{ app: strings }] = useStrings()
  const [auth, { fetch: authFetch, setHandleErrors }] = useFetchLogin()

  useEffect(() => {
    if (auth.state === FetchState.ERROR) {
      if (auth.status === 401 || auth.status === 403) {
        message.error(strings.login.wrongCredentials)
      } else if (auth.status < 0) {
        message.error(strings.error.http_unknown)
      } else {
        message.error(strings.error.http)
      }
    } else if (auth.state === FetchState.OK) {
      nextSession({
        auth: auth.data,
        god: props.god === true
      })
    }
  }, [auth])

  const validation: { username: Rule[]; password: Rule[] } = useMemo(() => {
    return {
      username: [
        { required: true, message: strings.validation.required },
        { type: 'email', message: strings.validation.email }
      ],
      password: [{ required: true, message: strings.validation.required }]
    }
  }, [strings])

  type FieldData = {
    username: string
    password: string
  }

  const handleSubmit = useCallback((values: FieldData) => {
    setHandleErrors(false)
    authFetch(values)
  }, [])

  return (
    <>
      <AppBrowserTitle text={strings.login.title} />
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.leftTextBlock}>
            <Space size={16} direction="vertical">
              <VYouLogo fullBlack />
              <Title level={2}>{strings.login.claimTitle}</Title>
              <Text className={classes.leftTextClaimFont}>
                {strings.login.claimHeadline}
              </Text>
            </Space>
          </div>
          <LoginClaimImage />
        </div>
        <div className={classes.right}>
          <Form
            onFinish={handleSubmit}
            layout="vertical"
            name="login_form"
            className={classes.rightForm}
          >
            <Title level={2}>{strings.login.title}</Title>
            <Form.Item
              label={strings.login.email}
              name="username"
              rules={validation.username}
              required
            >
              <Input prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              label={strings.login.password}
              name="password"
              rules={validation.password}
              required
            >
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={auth.state == FetchState.FETCHING}
                className={classes.rightFormSubmitButton}
              >
                {strings.login.send}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  )
}
