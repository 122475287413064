import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Col, Layout, Row } from 'antd'
import React, { useCallback, useState } from 'react'
import AppSpaced from '~component/layout/AppSpaced'
import { WithChildrenProp } from '~component/types'
import classes from './AppLTCLayout.less'

const { Header, Sider, Content } = Layout

export type AppLTCLayoutProps = {
  logo?: React.ComponentType
  left?: React.ComponentType
  top?: React.ComponentType
} & WithChildrenProp

export default function AppLTCLayout(props: AppLTCLayoutProps) {
  const [collapsed, setCollapsed] = useState(false)

  const CollapseIcon = useCallback(() => {
    return collapsed ? (
      <MenuUnfoldOutlined onClick={() => setCollapsed(false)} />
    ) : (
      <MenuFoldOutlined onClick={() => setCollapsed(true)} />
    )
  }, [collapsed])

  return (
    <Layout className={classes.root}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={classes.sidebar}
      >
        {props.logo && (
          <AppSpaced nb>
            <props.logo />
          </AppSpaced>
        )}
        {props.left && <props.left />}
      </Sider>
      <Layout className={classes.layout}>
        <Header className={classes.header}>
          <Row>
            <Col flex="40px">
              <AppSpaced nv>
                <CollapseIcon />
              </AppSpaced>
            </Col>
            <Col flex="auto">{props.top && <props.top />}</Col>
          </Row>
        </Header>
        <Content>{props.children}</Content>
      </Layout>
    </Layout>
  )
}
