import makeStore from '~lib/base/makeStore'
import { UserDTO } from '~service/dto'
import { fetchMe } from '~service/users'

export enum UserDataState {
  UNKNOWN = -1,
  KNOWN = 1
}

const {
  init: initUserData,
  addListener: addUserDataListener,
  getValue: getUserData,
  nextValue: nextUserData,
  clearValue: clearUserData,
  useStore: useUserData
} = makeStore<UserDTO>('user_data')

const refreshUserData = () => {
  fetchMe().then((me) => {
    if (!me.error) {
      nextUserData(me.data)
    }
  })
}

export {
  initUserData,
  refreshUserData,
  addUserDataListener,
  getUserData,
  clearUserData,
  nextUserData
}

export default useUserData
