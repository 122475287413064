import { motion } from 'framer-motion'
import React from 'react'
import { WithChildrenProp, WithClassNameProp } from '~component/types'

export default function MotionContent(
  props: WithChildrenProp & WithClassNameProp
) {
  return (
    <motion.div
      className={props.className}
      initial={{ translateY: 20, opacity: 0 }}
      animate={{ translateY: 1, opacity: 1 }}
      transition={{ duration: 0.612 }}
      exit={{ opacity: 0 }}>
      {props.children}
    </motion.div>
  )
}
