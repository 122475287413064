import React from 'react'
import { Link } from 'react-router-dom'
import { PaginationParams } from '~component/widget/AppDataGrid'
import useStrings from '~i18n/useStrings'
import { ScreenPath } from '~navigation/types'
import { CustomerDTO } from '~service/dto'
import { TenantsResponseItem } from '~service/tenants'

// TODO: deprecated this, use screenNavigation

export const EditTenantLink = (props: { tenant: TenantsResponseItem }) => {
  const { tenant } = props

  return (
    <Link
      to={{
        pathname: `/${ScreenPath.TENANTS}/${tenant.id}`,
        state: tenant
      }}
    >
      {tenant.name}
    </Link>
  )
}

export const AssignTenantToCustomerLink = (props: {
  customer: CustomerDTO
}) => {
  const [{ app: strings }] = useStrings()
  const { customer } = props

  return (
    <Link
      style={{ textTransform: 'uppercase' }}
      to={{
        pathname: `/${ScreenPath.CUSTOMERS}/${
          ScreenPath.CUSTOMERS_ASSIGN_TENANT
        }/${encodeURIComponent(customer.id)}`,
        state: customer
      }}
    >
      {strings.customers.assignTenant}
    </Link>
  )
}

// tenant customers

export const TenantCustomersLink = (props: {
  pagination?: PaginationParams
}) => {
  const [{ app: strings }] = useStrings()

  return (
    <Link
      to={{
        pathname: !props.pagination
          ? `/${ScreenPath.CUSTOMERS}`
          : `/${ScreenPath.CUSTOMERS}/${props.pagination.page}/${props.pagination.pageSize}`
      }}
    >
      {strings.tenantCustomers.title}
    </Link>
  )
}
