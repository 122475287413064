import {
  CheckOutlined,
  CloseOutlined,
  FormOutlined,
  GatewayOutlined,
  MailOutlined,
  TagOutlined,
  UserOutlined
} from '@ant-design/icons'
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Select,
  Skeleton,
  Space,
  Table,
  Tabs,
  Tag
} from 'antd'
import { Rule } from 'antd/lib/form'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react'
import { useLocation } from 'react-router-dom'
import MotionContent from '~component/widget/MotionContent'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import { openStripePopUp } from '~component/widget/StripeAuthPopUp'
import useStrings, { getStrings } from '~i18n/useStrings'
import {
  TagDTO,
  TagRequired,
  TagType,
  TenantDTO,
  TenantFieldValue
} from '~service/dto'
import {
  encodeUpdateTenantParams,
  TenantsResponseItem,
  useFetchAddTenantRole,
  useFetchAddTenantTag,
  useFetchStripeLink,
  useFetchTenantById,
  useFetchUpdateTenant,
  useFetchUpdateTenantFields
} from '~service/tenants'
import { FetchState } from '~service/useFetch'
import styles from './EditTenant.less'

const { TabPane } = Tabs

const LABEL_COL_SPAN = 6
const WRAPPER_COL_SPAN = 12

const FIELDS_WRAPPER_COL_SPAN = 6
const TAGS_WRAPPER_COL_SPAN = 6

export default function EditTenantScreen() {
  const { state: tenant } = useLocation<TenantsResponseItem | TenantDTO>()

  const [{ app: strings }] = useStrings()
  const [addTagForm] = Form.useForm()

  // fetch

  const [data, { fetch: fetchData }] = useFetchTenantById(tenant.id)
  const [stripelink, { fetch: fetchStripeLink }] = useFetchStripeLink(tenant.id)
  const [
    ,
    { fetch: fetchUpdateTenant, setSuffix: setUpdateTenantSuffix }
  ] = useFetchUpdateTenant()

  const [
    updateFields,
    { fetch: fetchUpdateFields }
  ] = useFetchUpdateTenantFields()
  const [addTag, { fetch: fetchAddTag }] = useFetchAddTenantTag()
  const [addRole, { fetch: fetchAddRole }] = useFetchAddTenantRole()

  // state

  const addRoleInputValueRef = useRef<string>('')
  const addRoleInputRef = useRef<Input>(null)

  type State = { tenant?: TenantDTO }
  type Action = { type: 'init'; payload: TenantDTO }

  const [state, dispatch] = useReducer(
    (state: State, action: Action): State => {
      if (action.type === 'init') {
        return {
          tenant: action.payload
        }
      }
      return state
    },
    {}
  )

  useEffect(() => {
    if (data.state === FetchState.IDLE) {
      fetchData()
    } else if (data.state === FetchState.OK) {
      dispatch({ type: 'init', payload: data.data })
    }
  }, [data])

  useEffect(() => {
    if (updateFields.state === FetchState.OK) {
      const { app: strings } = getStrings()
      message.success(strings.editTenant.updateFieldsSuccess)

      dispatch({ type: 'init', payload: updateFields.data })
    }
  }, [updateFields])

  useEffect(() => {
    if (addTag.state === FetchState.OK) {
      const { app: strings } = getStrings()
      message.success(strings.editTenant.addTagSuccess)

      addTagForm.resetFields()
      dispatch({ type: 'init', payload: addTag.data })
    }
  }, [addTag])

  useEffect(() => {
    if (addRole.state === FetchState.OK) {
      const { app: strings } = getStrings()
      message.success(strings.editTenant.addRoleSuccess)

      addRoleInputValueRef.current = ''
      addRoleInputRef.current?.setValue('')
      dispatch({ type: 'init', payload: addRole.data })
    }
  }, [addRole])

  useEffect(() => {
    if (stripelink.state === FetchState.OK) {
      openStripePopUp(stripelink.data.link)
    }
  }, [stripelink])

  // validation

  const validation: {
    required: Rule[]
    email: Rule[]
  } = useMemo(() => {
    return {
      required: [{ required: true, message: strings.validation.required }],
      email: [
        { required: true, message: strings.validation.required },
        { type: 'email', message: strings.validation.email }
      ]
    }
  }, [strings])

  // tags tab

  const tagsColumns = useMemo(() => {
    return [
      {
        title: strings.editTenant.tagName,
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: strings.editTenant.tagType,
        dataIndex: 'type',
        key: 'type',
        render: (value: string) => {
          if (value === 'string') {
            return strings.editTenant.tagTypeString
          } else if (value === 'number') {
            return strings.editTenant.tagTypeNumber
          } else if (value === 'email') {
            return strings.editTenant.tagTypeEmail
          } else {
            return '-'
          }
        }
      },
      {
        title: strings.editTenant.tagRequired,
        dataIndex: 'required',
        key: 'required',
        align: 'center',
        render: (value: string) => {
          return value === TagRequired.REQUIRED ? (
            <CheckOutlined className={styles.greenIcon} />
          ) : (
            <CloseOutlined className={styles.redIcon} />
          )
        }
      }
    ]
  }, [strings])

  // handles

  type UpdateData = {
    name: string
    admin: string
    contact: string
  }
  const handleUpdateData = useCallback(
    (data: UpdateData) => {
      if (state.tenant) {
        const { name, admin, contact } = data
        const nextTenant: TenantDTO = { ...state.tenant, name, admin, contact }
        setUpdateTenantSuffix(encodeUpdateTenantParams(nextTenant))
        fetchUpdateTenant(nextTenant)
      }
    },
    [state.tenant]
  )

  type UpdateFields = {
    name: TenantFieldValue
    gender: TenantFieldValue
    phone: TenantFieldValue
    surname: TenantFieldValue
    birth_date: TenantFieldValue
  }
  const handleUpdateFields = useCallback(
    (data: UpdateFields) => {
      if (state.tenant) {
        fetchUpdateFields({
          id: state.tenant.id,
          fields: data
        })
      }
    },
    [state.tenant]
  )

  type UpdateTags = { name: string; type: TagType; required: boolean }
  const handleUpdateTags = useCallback(
    (data: UpdateTags) => {
      if (!state.tenant) {
        return
      }

      fetchAddTag({
        id: state.tenant.id,
        name: data.name,
        type: data.type,
        required: data.required ? TagRequired.REQUIRED : TagRequired.OPTIONAL
      })
    },
    [state.tenant]
  )

  const handleAddRoleInputChange = useCallback(
    (ev: { target: { value: string } }) => {
      addRoleInputValueRef.current = ev.target.value
    },
    []
  )

  const handleAddRole = useCallback(() => {
    if (!state.tenant) {
      return
    }

    fetchAddRole({ id: state.tenant.id, name: addRoleInputValueRef.current })
  }, [state.tenant])

  const handleOpenStripePopup = useCallback(() => {
    fetchStripeLink()
  }, [])

  // render

  const title = state.tenant?.name ?? ''

  return (
    <>
      <AppBrowserTitle text={title} />
      <MotionContent>
        <PageHeader
          className={styles.header}
          onBack={() => window.history.back()}
          title={title}
        />
        <Card className={styles.card}>
          {data.state === FetchState.FETCHING && <Skeleton active />}
          {state.tenant && (
            <Tabs
              defaultActiveKey="data"
              tabPosition="left"
              tabBarStyle={{ width: 150 }}
            >
              <TabPane
                tab={
                  <span>
                    <FormOutlined />
                    {strings.editTenant.data}
                  </span>
                }
                key="data"
              >
                <Form
                  initialValues={state.tenant}
                  autoComplete="off"
                  layout="horizontal"
                  labelCol={{ span: LABEL_COL_SPAN }}
                  wrapperCol={{ span: WRAPPER_COL_SPAN }}
                  onFinish={handleUpdateData as any}
                  name="edit_tenant_data"
                >
                  <Form.Item
                    label={strings.editTenant.name}
                    name="name"
                    rules={validation.required}
                    required
                  >
                    <Input
                      disabled
                      prefix={<UserOutlined />}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item
                    label={strings.editTenant.admin}
                    name="admin"
                    rules={validation.email}
                    required
                  >
                    <Input
                      disabled
                      prefix={<MailOutlined />}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item
                    label={strings.editTenant.contact}
                    name="contact"
                    rules={validation.email}
                    required
                  >
                    <Input
                      disabled
                      prefix={<MailOutlined />}
                      autoComplete="off"
                    />
                  </Form.Item>
                  {state.tenant.stripe && (
                    <Form.Item wrapperCol={{ offset: LABEL_COL_SPAN }}>
                      <Button
                        loading={stripelink.state === FetchState.FETCHING}
                        onClick={handleOpenStripePopup}
                        type="primary"
                      >
                        {strings.editTenant.openStripeLink}
                      </Button>
                    </Form.Item>
                  )}
                  {/*<Form.Item wrapperCol={{ offset: LABEL_COL_SPAN }}>
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={updateTenant.state === FetchState.FETCHING}>
                      {strings.addTenant.send}
                    </Button>
              </Form.Item>*/}
                </Form>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <GatewayOutlined />
                    {strings.editTenant.fields}
                  </span>
                }
                key="fields"
              >
                <Form
                  initialValues={state.tenant.mandatory_fields}
                  autoComplete="off"
                  layout="horizontal"
                  labelCol={{ span: LABEL_COL_SPAN }}
                  wrapperCol={{ span: FIELDS_WRAPPER_COL_SPAN }}
                  onFinish={handleUpdateFields as any}
                  name="edit_tenant_fields"
                >
                  <Form.Item
                    label={strings.editTenant.fieldName}
                    name="name"
                    rules={validation.required}
                    required
                  >
                    <Select placeholder={strings.editTenant.selectPlaceholder}>
                      <Select.Option value={TenantFieldValue.REQUIRED}>
                        {strings.editTenant.fieldRequired}
                      </Select.Option>
                      <Select.Option value={TenantFieldValue.OPTIONAL}>
                        {strings.editTenant.fieldOptional}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={strings.editTenant.fieldSurname}
                    name="surname"
                    rules={validation.required}
                    required
                  >
                    <Select placeholder={strings.editTenant.selectPlaceholder}>
                      <Select.Option value={TenantFieldValue.REQUIRED}>
                        {strings.editTenant.fieldRequired}
                      </Select.Option>
                      <Select.Option value={TenantFieldValue.OPTIONAL}>
                        {strings.editTenant.fieldOptional}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={strings.editTenant.fieldGender}
                    name="gender"
                    rules={validation.required}
                    required
                  >
                    <Select placeholder={strings.editTenant.selectPlaceholder}>
                      <Select.Option value={TenantFieldValue.REQUIRED}>
                        {strings.editTenant.fieldRequired}
                      </Select.Option>
                      <Select.Option value={TenantFieldValue.OPTIONAL}>
                        {strings.editTenant.fieldOptional}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={strings.editTenant.fieldPhone}
                    name="phone"
                    rules={validation.required}
                    required
                  >
                    <Select placeholder={strings.editTenant.selectPlaceholder}>
                      <Select.Option value={TenantFieldValue.REQUIRED}>
                        {strings.editTenant.fieldRequired}
                      </Select.Option>
                      <Select.Option value={TenantFieldValue.OPTIONAL}>
                        {strings.editTenant.fieldOptional}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={strings.editTenant.fieldBirthDate}
                    name="birth_date"
                    rules={validation.required}
                    required
                  >
                    <Select placeholder={strings.editTenant.selectPlaceholder}>
                      <Select.Option value={TenantFieldValue.REQUIRED}>
                        {strings.editTenant.fieldRequired}
                      </Select.Option>
                      <Select.Option value={TenantFieldValue.OPTIONAL}>
                        {strings.editTenant.fieldOptional}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: LABEL_COL_SPAN }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={updateFields.state === FetchState.FETCHING}
                    >
                      {strings.addTenant.send}
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <TagOutlined />
                    {strings.editTenant.tags}
                  </span>
                }
                key="tags"
              >
                <Form
                  form={addTagForm}
                  autoComplete="off"
                  layout="horizontal"
                  labelCol={{ span: LABEL_COL_SPAN }}
                  wrapperCol={{ span: TAGS_WRAPPER_COL_SPAN }}
                  onFinish={handleUpdateTags as any}
                  name="edit_tenant_tags"
                >
                  <Form.Item
                    name="name"
                    rules={validation.required}
                    label={strings.editTenant.tagName}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    name="type"
                    rules={validation.required}
                    label={strings.editTenant.tagType}
                    required
                  >
                    <Select>
                      <Select.Option value={TagType.STRING}>
                        {strings.editTenant.tagTypeString}
                      </Select.Option>
                      <Select.Option value={TagType.NUMBER}>
                        {strings.editTenant.tagTypeNumber}
                      </Select.Option>
                      <Select.Option value={TagType.EMAIL}>
                        {strings.editTenant.tagTypeEmail}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="required"
                    valuePropName="checked"
                    wrapperCol={{ offset: TAGS_WRAPPER_COL_SPAN }}
                  >
                    <Checkbox>{strings.editTenant.tagRequired}</Checkbox>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: TAGS_WRAPPER_COL_SPAN }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={addTag.state === FetchState.FETCHING}
                    >
                      {strings.editTenant.addTag}
                    </Button>
                  </Form.Item>
                </Form>
                <Divider />
                <Table<TagDTO>
                  size="small"
                  rowKey="id"
                  dataSource={state.tenant.tags}
                  pagination={false}
                  columns={tagsColumns as any}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <UserOutlined />
                    {strings.editTenant.roles}
                  </span>
                }
                key="roles"
              >
                <Space>
                  <Input
                    ref={addRoleInputRef}
                    onChange={handleAddRoleInputChange}
                    size="small"
                    className={styles.addRoleInput}
                  />
                  <Button
                    size="small"
                    type="primary"
                    onClick={handleAddRole}
                    loading={addRole.state === FetchState.FETCHING}
                  >
                    {strings.editTenant.addRole}
                  </Button>
                </Space>
                <Divider />
                {state.tenant.allowed_roles.map((role, idx) => {
                  return (
                    <Tag className={styles.role} key={`tenant_tag_${idx}`}>
                      {role}
                    </Tag>
                  )
                })}
              </TabPane>
            </Tabs>
          )}
        </Card>
      </MotionContent>
    </>
  )
}
