// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._16F2YQBUUtJ-4M93KW-_d5 {\n  display: flex;\n  justify-content: center;\n  padding-left: 20px;\n  padding-right: 20px;\n}\n.WtKIKkZ4YRQS-yLUpYtM9 {\n  flex: 1;\n  max-width: 1024px;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"root": "_16F2YQBUUtJ-4M93KW-_d5",
	"inner": "WtKIKkZ4YRQS-yLUpYtM9"
};
module.exports = exports;
