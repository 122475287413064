import React from 'react'

export type VYouLogoProps = { fullBlack?: boolean } & React.SVGProps<
  SVGSVGElement
>

export default function VYouLogo(props: VYouLogoProps) {
  const { fullBlack, ...rest } = props
  if (fullBlack === true) {
    return (
      <svg width={80} height={98} viewBox="0 0 80 98" fill="none" {...rest}>
        <g opacity={0.98} fillRule="evenodd" clipRule="evenodd">
          <path
            d="M51.892 0c0 4.745-3.872 8.592-8.649 8.592H14.595l27.118 46.84s1.3 2.225 1.53 4.71c.166 1.794-.74 3.848-.74 3.848l-2.773 4.744L0 0h34.595"
            fill="#252644"
          />
          <path
            d="M49.73 51.82L80 0h-5.135s-2.213.003-4.054 1.074c-1.846 1.073-3.32 3.214-3.32 3.214L49.73 34.636l-7.582-12.884s-1.277-2.314-3.23-3.494c-1.824-1.104-4.323-1.074-4.323-1.074h-5.136l20.27 34.635z"
            fill="#292A46"
          />
          <path
            d="M29.19 89.408l-2.163-3.222-2.712 3.993-3.483-6.678h-4.075L24.324 98l4.865-8.592zM36.77 98v-6.96l5.392-7.539H37.59l-2.715 3.962-2.736-3.962h-4.572l5.373 7.538V98h3.83zm10.981-.39c-.74.26-1.541.39-2.404.39-.849 0-1.634-.13-2.355-.39a5.425 5.425 0 01-1.863-1.11 5.153 5.153 0 01-1.225-1.726c-.297-.669-.445-1.413-.445-2.232 0-.74.148-1.432.444-2.075a5.094 5.094 0 011.236-1.677 5.864 5.864 0 011.882-1.12 6.734 6.734 0 012.384-.41c.85 0 1.638.133 2.365.4a5.84 5.84 0 011.892 1.11 4.98 4.98 0 011.245 1.697c.296.656.444 1.38.444 2.173a5.13 5.13 0 01-.453 2.173 5.154 5.154 0 01-1.245 1.696 5.564 5.564 0 01-1.902 1.101zm-2.346-2.832c-.295 0-.576-.055-.84-.164a2.035 2.035 0 01-.684-.457 2.323 2.323 0 01-.462-.686 2.104 2.104 0 01-.176-.868c0-.293.059-.567.176-.823a2.36 2.36 0 01.462-.676c.19-.195.419-.348.684-.457.264-.11.545-.165.84-.165.296 0 .576.055.841.165.265.11.493.262.684.457.19.195.345.423.462.685.117.262.175.54.175.832 0 .305-.058.588-.175.85-.117.262-.271.49-.462.686a2.036 2.036 0 01-.684.457c-.265.11-.545.164-.84.164zm17.023 2.225c-.905.665-2.254.997-4.05.997-1.795 0-3.145-.332-4.049-.997-.904-.665-1.356-1.662-1.356-2.992v-6.214h3.584v5.543c0 1.214.607 1.822 1.821 1.822 1.215 0 1.822-.608 1.822-1.822v-5.543h3.584v6.214c0 1.33-.452 2.327-1.356 2.992z"
            fill="#252644"
          />
        </g>
      </svg>
    )
  }

  return (
    <svg width={39} height={39} viewBox="0 0 39 39" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.297.407c0 2.63-1.887 4.761-4.216 4.761H7.115l13.22 25.96s.634 1.232.746 2.61c.08.993-.36 2.132-.36 2.132l-1.353 2.629L0 .407h16.865"
        fill="#252644"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.243 29.125L39 .407h-2.503s-1.08.002-1.977.595c-.9.595-1.618 1.781-1.618 1.781l-8.659 16.819-3.696-7.14s-.622-1.282-1.574-1.937c-.89-.612-2.108-.595-2.108-.595h-2.503l9.881 19.195z"
        fill="#01FCB2"
      />
    </svg>
  )
}
