import React, { useMemo } from 'react'
import { Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'

export const DEFAULT_PAGE_SIZE = 15

export type PaginationParams = {
  page: number
  pageSize: number
}

export type AppDataGridColum<T> = {
  title: string
  width?: number
  render: (data: T) => React.ReactNode
}

export type AppDataGridProps<T extends object> = {
  data: Array<T>
  columns: Array<AppDataGridColum<T>>
  dataKey: keyof T
  total?: number
  page?: number
  pageSize?: number
  onPageChange?: (page: number, pageSize?: number) => void
}

export default function AppDataGrid<T extends object>(
  props: AppDataGridProps<T>
) {
  const columns = useMemo(() => {
    return props.columns.map((column) => {
      return {
        ...column,
        width: column.width ? `${column.width}%` : undefined,
        ellipsis: true
      }
    })
  }, [props.columns])

  const pagination: TablePaginationConfig | boolean = useMemo(() => {
    if (
      props.page === undefined &&
      props.pageSize === undefined &&
      props.onPageChange === undefined
    ) {
      return false
    }
    return {
      position: ['bottomCenter'],
      total: props.total,
      onChange: props.onPageChange ?? undefined,
      current: props.page !== undefined ? props.page : 1,
      pageSize:
        props.pageSize !== undefined ? props.pageSize : DEFAULT_PAGE_SIZE
    } as TablePaginationConfig
  }, [props.page, props.pageSize, props.onPageChange])

  return (
    <Table<T>
      size="small"
      columns={columns}
      rowKey={props.dataKey as string}
      dataSource={props.data}
      pagination={pagination}
    />
  )
}
