import { ConfigProvider } from 'antd'
import React, { Suspense, useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import useStrings, { initStrings } from '~i18n/useStrings'
import useSession, { addSessionListener, initSession } from '~lib/useSession'
import { clearUserData, refreshUserData } from '~lib/useUserData'
import AdminNavigation from '~navigation/AdminNavigation'
import AuthNavigation from '~navigation/AuthNavigation'
import GodAdminNavigation from '~navigation/GodAdminNavigation'
import { invalidateFetchCache } from '~service/useFetch'
import './App.css'

// TODO: dynamic imports for code splitting
//const LoginScreen = loadable(() =>
//import(/* webpackChunkName: 'login' */ '~component/screen/auth/Login')
//)

const Splash = () => {
  // TODO: show something? a logo?
  return <div />
}

export default function App() {
  const [strings] = useStrings()
  const [session] = useSession()
  const [inited, setInited] = useState<boolean>(false)

  useEffect(() => {
    const subscription = addSessionListener((session) => {
      if (session !== null) {
        refreshUserData()
      } else {
        invalidateFetchCache()
        clearUserData()
      }
    })

    Promise.all([initStrings(), initSession()]).then(() => setInited(true))

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  if (!inited) {
    return <Splash />
  }

  return (
    <ConfigProvider locale={strings.antd}>
      <HelmetProvider>
        <Suspense fallback={Splash}>
          {session === null && <AuthNavigation />}
          {session !== null && session.god === true && <GodAdminNavigation />}
          {session !== null && session.god === false && <AdminNavigation />}
        </Suspense>
      </HelmetProvider>
    </ConfigProvider>
  )
}
