import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import AssignTenantToCustomerScreen from '~component/screen/customer/AssignTenantToCustomer'
import CustomersScreen from '~component/screen/customer/Customers'
import AddNewTenantScreen from '~component/screen/tenant/AddNewTenant'
import EditTenantScreen from '~component/screen/tenant/EditTenant'
import TenantsScreen from '~component/screen/tenant/Tenants'
import AdminLayout from '~component/template/AdminLayout'
import { ScreenPath } from '~navigation/types'

export default function GodAdminNavigation() {
  return (
    <Router>
      <AdminLayout>
        <Switch>
          <Route path={`/${ScreenPath.TENANTS}/${ScreenPath.TENANTS_ADD_NEW}`}>
            <AddNewTenantScreen />
          </Route>
          <Route path={`/${ScreenPath.TENANTS}/:id`}>
            <EditTenantScreen />
          </Route>
          <Route path={`/${ScreenPath.TENANTS}`}>
            <TenantsScreen />
          </Route>
          <Route
            path={`/${ScreenPath.CUSTOMERS}/${ScreenPath.CUSTOMERS_ASSIGN_TENANT}/:id`}
          >
            <AssignTenantToCustomerScreen />
          </Route>
          <Route path={`/${ScreenPath.CUSTOMERS}`}>
            <CustomersScreen />
          </Route>
          <Route path="*">
            <Redirect to={`/${ScreenPath.TENANTS}`} />
          </Route>
        </Switch>
      </AdminLayout>
    </Router>
  )
}
