import { UserDTO, CustomerGender } from '~service/dto'
import { FetchMethod, useMockFetch, makeMockFetch } from '~service/useFetch'

// master

export type MeResponse = UserDTO

const mockResponse: MeResponse = {
  id: 'b612',
  email: 'mockuser@mockdomain.com',
  name: 'John',
  surname: 'Doe',
  birth_date: '2020-06-12T12:40:14+0000',
  phone: '+34 612 612 612',
  gender: CustomerGender.MALE,
  created: ''
}

export const useFetchMe = () =>
  useMockFetch<MeResponse>(FetchMethod.GET, '/api/user/me', {
    mockResponse
  })

export const fetchMe = () =>
  makeMockFetch<MeResponse>(FetchMethod.GET, '/api/user/me', undefined, {
    mockResponse
  })
