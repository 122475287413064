import {
  CheckOutlined,
  CloudOutlined,
  FormOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Space,
  Steps,
  Typography
} from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Rule } from 'antd/lib/form'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { OneSignalIcon, StripeIcon } from '~component/screen/tenant/image'
import MotionContent from '~component/widget/MotionContent'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import SecretText from '~component/widget/SecretText'
import StripeAuthPopUp, { StripeAuth } from '~component/widget/StripeAuthPopUp'
import useStrings, { getStrings } from '~i18n/useStrings'
import useNavigation from '~navigation/useNavigation'
import {
  ConnectTenantResponse,
  AddTenantResponse,
  useFetchAddTenant,
  useFetchConnectTenant
} from '~service/tenants'
import { FetchState } from '~service/useFetch'
import styles from './AddNewTenant.less'

const { Step } = Steps

const LABEL_COL_SPAN = 4
const WRAPPER_COL_SPAN = 16

export default function AddNewTenantScreen() {
  const [{ app: strings }] = useStrings()
  const [, { goToTenant }] = useNavigation()

  // fetch

  const [addTenant, { fetch: fetchAddTenant }] = useFetchAddTenant()
  const [connectTenant, fetchConnectTennant] = useFetchConnectTenant()

  // state

  type State = {
    step: 0 | 1 | 2
    showStripePopUp: boolean
    checkOneSignal: boolean
    checkStripe: boolean
    dataAddTenant?: AddTenantResponse
    dataStripeAuth?: StripeAuth
    dataConnect?: ConnectTenantResponse
  }
  type Action =
    | { type: 'tenantCreated'; payload: AddTenantResponse }
    | { type: 'tenantConnected'; payload: ConnectTenantResponse }
    | { type: 'checkOneSignal'; payload: boolean }
    | { type: 'checkStripe'; payload: boolean }
    | { type: 'showStripePopUp'; payload: boolean }
    | { type: 'stripeAuth'; payload: StripeAuth }

  const [state, dispatch] = useReducer(
    (state: State, action: Action): State => {
      if (action.type === 'tenantCreated') {
        return { ...state, dataAddTenant: action.payload, step: 1 }
      } else if (action.type === 'tenantConnected') {
        return { ...state, dataConnect: action.payload, step: 2 }
      } else if (action.type === 'checkOneSignal') {
        const nextDataTenant: AddTenantResponse = { ...state.dataAddTenant! }
        nextDataTenant.tenant.oneSignal = action.payload
        return {
          ...state,
          checkOneSignal: action.payload,
          dataAddTenant: nextDataTenant
        }
      } else if (action.type === 'checkStripe') {
        const nextDataTenant: AddTenantResponse = { ...state.dataAddTenant! }
        nextDataTenant.tenant.stripe = action.payload
        return {
          ...state,
          checkStripe: action.payload,
          dataAddTenant: nextDataTenant
        }
      } else if (action.type === 'showStripePopUp') {
        return { ...state, showStripePopUp: action.payload }
      } else if (action.type === 'stripeAuth') {
        return { ...state, dataStripeAuth: action.payload, checkStripe: true }
      }
      return state
    },
    {
      step: 0,
      showStripePopUp: false,
      checkOneSignal: false,
      checkStripe: false
    }
  )

  // validation

  const validation: {
    required: Rule[]
    email: Rule[]
  } = useMemo(() => {
    return {
      required: [{ required: true, message: strings.validation.required }],
      email: [
        { required: true, message: strings.validation.required },
        { type: 'email', message: strings.validation.email }
      ]
    }
  }, [strings])

  type FieldData = {
    name: string
    email: string
    contact: string
    password: string
  }

  // flow

  const handlSubmitStep1 = useCallback((values: FieldData) => {
    fetchAddTenant(values)
  }, [])

  const handleSubmitStep2 = useCallback(() => {
    fetchConnectTennant(
      state.dataAddTenant!,
      state.checkOneSignal,
      state.checkStripe && state.dataStripeAuth !== undefined
        ? state.dataStripeAuth
        : undefined
    )
  }, [state])

  useEffect(() => {
    if (addTenant.state === FetchState.OK) {
      const { app: strings } = getStrings()
      message.success(strings.addTenant.addTenantSuccess)

      dispatch({ type: 'tenantCreated', payload: addTenant.data })
    }
  }, [addTenant])

  useEffect(() => {
    if (connectTenant.state === FetchState.OK) {
      const { app: strings } = getStrings()
      message.success(strings.addTenant.connectTenantSuccess)

      dispatch({ type: 'tenantConnected', payload: connectTenant.data })
    }
  }, [connectTenant])

  // handles

  const handleChangeOneSignal = useCallback((ev: CheckboxChangeEvent) => {
    dispatch({ type: 'checkOneSignal', payload: ev.target.checked })
  }, [])

  const handleChangeStripe = useCallback((ev: CheckboxChangeEvent) => {
    dispatch({ type: 'checkStripe', payload: ev.target.checked })
  }, [])

  const handleBeginStripeAuth = useCallback(() => {
    dispatch({ type: 'showStripePopUp', payload: true })
  }, [])

  const handleEndStripeAuth = useCallback((auth: StripeAuth) => {
    dispatch({ type: 'stripeAuth', payload: auth })

    setTimeout(() => {
      Modal.info({
        content: (
          <Typography.Paragraph>
            {strings.addTenant.stripeAuthSucess}
          </Typography.Paragraph>
        )
      })
    }, 612)
  }, [])

  const handleStripePopupClose = useCallback(() => {
    dispatch({ type: 'showStripePopUp', payload: false })
  }, [])

  const handleFinalize = useCallback(() => {
    if (!state.dataAddTenant) {
      return
    }
    goToTenant(state.dataAddTenant.tenant)
  }, [state.dataAddTenant, goToTenant])

  return (
    <>
      <AppBrowserTitle text={strings.tenants.newTenant} />
      <MotionContent>
        <PageHeader
          className={styles.header}
          onBack={() => window.history.back()}
          title={
            state.dataAddTenant !== undefined
              ? state.dataAddTenant.tenant.name
              : strings.tenants.newTenant
          }
        />
        <Card>
          <div className={styles.columns}>
            <Steps
              direction="vertical"
              size="small"
              current={state.step}
              className={styles.steps}
            >
              <Step
                title={strings.addTenant.step1}
                description={strings.addTenant.step1Hint}
                icon={<FormOutlined />}
              />
              <Step
                title={strings.addTenant.step2}
                description={strings.addTenant.step2Hint}
                icon={<CloudOutlined />}
              />
              <Step title={strings.addTenant.step3} icon={<CheckOutlined />} />
            </Steps>
            <Divider type="vertical" className={styles.divider} />
            <div className={styles.content}>
              {state.step === 0 && (
                <Form
                  autoComplete="off"
                  layout="horizontal"
                  labelCol={{ span: LABEL_COL_SPAN }}
                  wrapperCol={{ span: WRAPPER_COL_SPAN }}
                  onFinish={handlSubmitStep1 as any}
                  name="new_tenant"
                >
                  <Form.Item
                    label={strings.addTenant.name}
                    name="name"
                    rules={validation.required}
                    required
                  >
                    <Input prefix={<UserOutlined />} autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label={strings.addTenant.email}
                    name="email"
                    rules={validation.email}
                    required
                  >
                    <Input prefix={<MailOutlined />} autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label={strings.addTenant.contact}
                    name="contact"
                    rules={validation.email}
                    required
                  >
                    <Input prefix={<MailOutlined />} autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label={strings.addTenant.password}
                    name="password"
                    wrapperCol={{ span: 6 }}
                    rules={validation.required}
                    required
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: LABEL_COL_SPAN }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={addTenant.state === FetchState.FETCHING}
                    >
                      {strings.addTenant.send}
                    </Button>
                  </Form.Item>
                </Form>
              )}
              {state.step === 1 && (
                <>
                  <Typography.Paragraph>
                    {strings.addTenant.pickServices}
                  </Typography.Paragraph>
                  <Divider />
                  <Checkbox
                    onChange={handleChangeOneSignal}
                    checked={state.checkOneSignal}
                  >
                    <Space>
                      <Avatar size="large" icon={<OneSignalIcon />} />
                      <Typography.Text strong>OneSignal</Typography.Text>
                    </Space>
                  </Checkbox>
                  <Divider />
                  <Checkbox
                    onChange={handleChangeStripe}
                    checked={state.checkStripe}
                    disabled={state.dataStripeAuth === undefined}
                  >
                    <Space>
                      <Avatar size="large" icon={<StripeIcon />} />
                      <Typography.Text strong>Stripe</Typography.Text>
                      {state.dataStripeAuth === undefined && (
                        <Button
                          onClick={handleBeginStripeAuth}
                          type="primary"
                          size="small"
                        >
                          {strings.addTenant.stripeAuth}
                        </Button>
                      )}
                    </Space>
                  </Checkbox>
                  <Divider />
                  <Button
                    type="primary"
                    onClick={handleSubmitStep2}
                    loading={connectTenant.state === FetchState.FETCHING}
                  >
                    {strings.addTenant.send}
                  </Button>
                </>
              )}
              {state.step === 2 && (
                <>
                  <Typography.Paragraph>
                    <Typography.Text strong>tenantId</Typography.Text>
                    <br />
                    <SecretText text={state.dataConnect!.tenantId} />
                  </Typography.Paragraph>

                  <Typography.Paragraph>
                    <Typography.Text strong>clientId</Typography.Text>
                    <br />
                    <SecretText text={state.dataConnect!.clientId} />
                  </Typography.Paragraph>

                  <Typography.Paragraph>
                    <Typography.Text strong>clientSecret</Typography.Text>
                    <br />
                    <SecretText text={state.dataConnect!.clientSecret} />
                  </Typography.Paragraph>

                  {state.dataConnect!.oneSignalSecret && (
                    <Typography.Paragraph>
                      <Typography.Text strong>oneSignalSecret</Typography.Text>
                      <br />
                      <SecretText text={state.dataConnect!.oneSignalSecret} />
                    </Typography.Paragraph>
                  )}

                  {state.dataConnect!.stripeUserId && (
                    <Typography.Paragraph>
                      <Typography.Text strong>stripeUserId</Typography.Text>
                      <br />
                      <SecretText text={state.dataConnect!.stripeUserId} />
                    </Typography.Paragraph>
                  )}

                  <Space>
                    <Button type="primary" onClick={handleFinalize}>
                      {strings.addTenant.finalize}
                    </Button>
                  </Space>
                </>
              )}
            </div>
          </div>
        </Card>
      </MotionContent>
      {state.showStripePopUp && (
        <StripeAuthPopUp
          tenantId={state.dataAddTenant!.tenant.id}
          onAuth={handleEndStripeAuth}
          onClose={handleStripePopupClose}
        />
      )}
    </>
  )
}
