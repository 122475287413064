import { FetchMethod, useFetch, useMockFetch } from '~service/useFetch'
import { TenantCustomerDTO, CustomerGender, CustomerDTO } from '~service/dto'

// master

export type TenantCustomersItem = Pick<
  TenantCustomerDTO,
  'id' | 'email' | 'name' | 'surname' | 'birth_date' | 'phone'
>

const mockTenantCustomerItem: TenantCustomersItem = {
  id: '612',
  name: 'John',
  surname: 'McEnroe Flowers',
  email: 'john@mcenroe.com',
  birth_date: '2020-06-12T12:40:14+0000',
  phone: '+34 612 612 612'
}
const mockTenantCustomers: Array<TenantCustomersItem> = []
for (let i = 0; i < 100; i += 1) {
  mockTenantCustomers.push({
    ...mockTenantCustomerItem,
    email: `john_${i}@mcenroe.com`,
    id: `item$${i}`
  })
}

export type TenantCustomersRespone = Array<TenantCustomersItem>

export const useFetchTenantCustomers = () =>
  useMockFetch<TenantCustomersRespone>(FetchMethod.GET, '/api/user/me', {
    mockResponse: mockTenantCustomers
  })

// detail

export type TenantCustomerResponse = TenantCustomerDTO

const mockTenantCustomer: TenantCustomerResponse = {
  ...mockTenantCustomerItem,
  gender: CustomerGender.MALE,
  created: new Date().toDateString()
}

export const useFetchTenantCustomer = () =>
  useMockFetch<TenantCustomerResponse>(FetchMethod.GET, '/api/user/me', {
    mockResponse: mockTenantCustomer
  })

// update

export const useFetchUpdateTenantCustomer = () =>
  useMockFetch<TenantCustomerResponse, CustomerDTO>(
    FetchMethod.POST,
    '/api/user/me',
    {
      mockResponse: mockTenantCustomer
    }
  )

// delete

export const useFetchDeleteTenantCustomer = () =>
  useMockFetch<void>(FetchMethod.DELETE, '/api/user/me', {
    mockResponse: undefined
  })
