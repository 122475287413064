export function getArrayPage<T>(
  input: Array<T>,
  page: number,
  pageSize: number
): Array<T> {
  const output: Array<T> = []
  const offset = (page - 1) * pageSize
  for (let i = 0; i < pageSize && offset + i < input.length; i += 1) {
    output[i] = input[offset + i]
  }
  return output
}
