import React from 'react'
import { Helmet } from 'react-helmet-async'
import useStrings from '~i18n/useStrings'

export default function AppBrowserTitle(props: { text: string }) {
  const [{ app: strings }] = useStrings()

  return (
    <Helmet>
      <title>
        {strings.global.title} | {props.text}
      </title>
    </Helmet>
  )
}
