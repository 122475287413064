import { AnimatePresence } from 'framer-motion'
import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import LoginScreen from '~component/screen/auth/Login'
import { ScreenPath } from '~navigation/types'

export default function AuthNavigation() {
  return (
    <AnimatePresence>
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginScreen />
          </Route>
          <Route path="*">
            <Redirect to={`/${ScreenPath.LOGIN}`} />
          </Route>
        </Switch>
      </Router>
    </AnimatePresence>
  )
}
