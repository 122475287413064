import React, { useEffect } from 'react'
import AppConfig from '~config/AppConfig'

// https://github.com/Ramshackle-Jamathon/react-oauth-popup/blob/master/src/index.js

export type StripeAuth = { code: string; state: string }

export type StripeAuthPopUpProps = {
  onClose: () => void
  onAuth: (auth: StripeAuth) => void
  tenantId: string
}

const WINDOW_WIDTH = 600
const WINDOW_HEIGHT = 800

export const openStripePopUp = (url: string) => {
  const left = window.screenX + (window.outerWidth - WINDOW_WIDTH) / 2
  const top = window.screenY + (window.outerHeight - WINDOW_HEIGHT) / 2.5

  return window.open(
    url,
    undefined,
    `width=${WINDOW_WIDTH},height=${WINDOW_HEIGHT},left=${left},top=${top}`
  )
}

export default function StripeAuthPopUp(props: StripeAuthPopUpProps) {
  useEffect(() => {
    const url =
      'https://dashboard.stripe.com/express/oauth/authorize?response_type=code&stripe_user[business_type]=company&client_id=ca_HLhJ37sZCj84vE4YFxrb2084dMD7o8YO&scope=read_write&state=' +
      props.tenantId + '&redirect_uri=' + AppConfig.API_BASEURL + '/stripe_callback.html'

    const externalWindow = openStripePopUp(url)
    let timer: number | undefined
    if (externalWindow) {
      const checkAuthFromLocation = () => {
        const url = externalWindow.location.href
        if (url.indexOf('http') < 0) {
          // about:blank
          return
        }

        // http://local.apium.com:1234/tenant/stripe?code=ac_HUJNuKSm6lKIZuVY2Av9MtnTqzMIcYk6&state=612a
        const params = new URL(url).searchParams
        const code = params.get('code')
        const state = params.get('state')

        if (code !== null && state !== null) {
          props.onAuth({ code, state })
        }
        externalWindow.close()
      }

      // TODO: must be a better way
      // for the record:
      // to avoid cross domain issues, this is the "only" way to detect
      // if a window is closed or its location
      timer = setInterval(() => {
        try {
          checkAuthFromLocation()
        } catch {}

        if (externalWindow.closed) {
          clearInterval(timer)
          timer = undefined
          props.onClose()
        }
      }, 400)
    }

    return () => {
      if (timer) clearInterval(timer)
      if (externalWindow && !externalWindow.closed) {
        props.onClose()
      }
    }
  }, [])

  return <></>
}
