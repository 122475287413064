import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, PageHeader, Skeleton, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import MotionContent from '~component/widget/MotionContent'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import useStrings from '~i18n/useStrings'
import { getArrayPage } from '~lib/pagedArray'
import { EditTenantLink } from '~navigation/links'
import useNavigation from '~navigation/useNavigation'
import { TenantsResponseItem, useFetchTenants } from '~service/tenants'
import { FetchState } from '~service/useFetch'
import styles from './Tenants.less'

const PAGE_SIZE = 20

export default function TenantsScreen() {
  const [{ app: strings }] = useStrings()
  const [tenants, { fetch: tenantsFetch }] = useFetchTenants()
  const [, { goToAddNewTenant }] = useNavigation()

  const columns = useMemo(() => {
    return [
      {
        title: strings.tenants.name,
        dataIndex: 'name',
        key: 'name',
        render: (_name: string, item: TenantsResponseItem) => {
          return <EditTenantLink tenant={item} />
        }
      },
      {
        title: strings.tenants.contact,
        dataIndex: 'contact',
        key: 'contact'
      },
      {
        title: strings.tenants.admin,
        dataIndex: 'admin',
        key: 'admin'
      },
      {
        title: strings.tenants.oneSignal,
        dataIndex: 'oneSignal',
        key: 'oneSignal',
        align: 'center',
        render: (value: boolean) => {
          return value === true ? (
            <CheckOutlined className={styles.greenIcon} />
          ) : (
            <CloseOutlined className={styles.redIcon} />
          )
        }
      },
      {
        title: strings.tenants.stripe,
        dataIndex: 'stripe',
        key: 'stripe',
        align: 'center',
        render: (value: boolean) => {
          return value === true ? (
            <CheckOutlined className={styles.greenIcon} />
          ) : (
            <CloseOutlined className={styles.redIcon} />
          )
        }
      },
      {
        title: strings.tenants.created,
        dataIndex: 'created',
        key: 'created',
        render: (d: string | undefined) => {
          if (!d) {
            return ''
          }
          return new Date(d).toLocaleString()
        }
      }
    ]
  }, [strings])

  // state

  type State = {
    loading: boolean
    data: Array<TenantsResponseItem>
    pageData: Array<TenantsResponseItem>
    pagination: TablePaginationConfig
  }
  type Action =
    | {
        kind: 'init'
        payload: Array<TenantsResponseItem>
      }
    | {
        kind: 'page'
        payload: number
      }

  const [state, dispatch] = useReducer(
    (state: State, action: Action): State => {
      if (action.kind === 'init') {
        return {
          loading: false,
          data: action.payload,
          pageData: getArrayPage(action.payload, 1, PAGE_SIZE),
          pagination: {
            total: action.payload.length,
            current: 1,
            pageSize: PAGE_SIZE,
            onChange: (payload) => {
              dispatch({ kind: 'page', payload })
            }
          }
        }
      } else if (action.kind === 'page') {
        return {
          loading: false,
          data: state.data,
          pageData: getArrayPage(state.data, action.payload, PAGE_SIZE),
          pagination: {
            total: state.pagination.total,
            current: action.payload,
            pageSize: state.pagination.pageSize,
            onChange: state.pagination.onChange
          }
        }
      }
      return state
    },
    { loading: true, pageData: [], data: [], pagination: {} }
  )

  // on mount

  useEffect(() => {
    tenantsFetch()
  }, [])

  // on data

  useEffect(() => {
    if (tenants.state === FetchState.OK) {
      dispatch({ kind: 'init', payload: tenants.data })
    }
    // TODO: errors
  }, [tenants])

  // handles

  const handleAddNew = useCallback(() => goToAddNewTenant(), [])

  return (
    <>
      <AppBrowserTitle text={strings.tenants.title} />
      <MotionContent>
        <PageHeader
          className={styles.header}
          title={strings.tenants.title}
          extra={[
            <Button
              type="primary"
              key="add_new"
              icon={<PlusOutlined />}
              onClick={handleAddNew}
            >
              {strings.tenants.addNew}
            </Button>
          ]}
        />
        <Card className={styles.card}>
          {tenants.state === FetchState.FETCHING && <Skeleton active />}
          {tenants.state === FetchState.OK && (
            <Table<TenantsResponseItem>
              rowKey="id"
              dataSource={tenants.data}
              columns={columns as any}
            />
          )}
        </Card>
      </MotionContent>
    </>
  )
}
