import { FetchMethod, useFetch } from '~service/useFetch'
import { CustomerDTO } from '~service/dto'

export type CustomersResponse = Array<CustomerDTO>

export const useFetchCustomers = () =>
  useFetch<CustomersResponse>(FetchMethod.GET, '/api/v1/customers')

// assing tenant to customer

export type AssignTenantBody = {
  email: string
  tenantId: string
  tags: Array<{ [key: string]: string }>
  roles: Array<string>
}

export const useFetchAssignTenantToCustomer = () =>
  useFetch<void, AssignTenantBody>(FetchMethod.POST, '/api/v1/tenantassign')
