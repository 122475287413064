import { Menu } from 'antd'
import React, { useCallback } from 'react'
import { WithClassNameProp } from '~component/types'
import useStrings from '~i18n/useStrings'
import useNavigation from '~navigation/useNavigation'
import style from './AdminMenu.less'

const TENANTS_KEY = 'tenants'
const CUSTOMERS_KEY = 'customers'

// TODO: deprecated

export default function AdminMenu(_props: WithClassNameProp) {
  const [{ app: strings }] = useStrings()
  const [navState, nav] = useNavigation()

  const selected = navState.underCustomers ? CUSTOMERS_KEY : TENANTS_KEY

  const handleChange = useCallback(
    (ev: { key: React.Key }) => {
      if (ev.key === TENANTS_KEY) nav.goToTenants()
      else if (ev.key === CUSTOMERS_KEY) nav.goToCustomers()
    },
    [nav]
  )

  return (
    <Menu
      mode="horizontal"
      onClick={handleChange}
      defaultSelectedKeys={[selected]}
      className={style.root}
    >
      <Menu.Item key={TENANTS_KEY} className={style.item}>
        {strings.menu.tenants}
      </Menu.Item>
      <Menu.Item key={CUSTOMERS_KEY} className={style.item}>
        {strings.menu.customers}
      </Menu.Item>
    </Menu>
  )
}
