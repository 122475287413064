import { message } from 'antd'
import axios, { AxiosError, Method } from 'axios'
import { ISetupCache, setupCache } from 'axios-cache-adapter'
import * as localforage from 'localforage'
import { useMemo, useRef } from 'react'
import AppConfig from '~config/AppConfig'
import { getStrings } from '~i18n/useStrings'
import { useSafeState } from '~lib/base/useSafeState'
import { clearSession, getSession } from '~lib/useSession'

export const AGENT_ERROR_TIMEOUT = -100
export const AGENT_ERROR_UNKNOWN = -1

export enum FetchMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

export enum FetchState {
  IDLE = 0,
  FETCHING = 1,
  OK = 2,
  ERROR = -2
}

export type FetchRequest = object | void
export type FetchRequestOptions = {
  renewToken?: boolean
  cache?: { key: string }
}
export type FetchBody = object | FormData | undefined

// util

export const sanitizeFetchErrorDataAsMessage = (res: {
  data?: any
  status: number
}) => {
  return res.data && res.data.length && res.data.indexOf('<') < 0
    ? res.data
    : `Error ${res.status}`
}

// cache

const cacheStore = localforage.createInstance({
  name: AppConfig.CACHE_KEY
})

export const invalidateFetchCache = () => cacheStore.clear()

export const invalidateFetchCacheWithKey = (key: string) =>
  cacheStore.removeItem(key)

// agent

export type AgentErrorResponse = {
  error: true
  status: number
  data?: any
}
export type AgentSuccessResponse<T> = {
  error: false
  data: T
  fromCache: boolean
}
export type AgentResponse<T> = AgentErrorResponse | AgentSuccessResponse<T>

const makeAgentRequest = <T>(
  method: Method,
  url: string,
  data: object | undefined,
  options?: FetchRequestOptions
): Promise<AgentResponse<T>> =>
  new Promise<AgentResponse<T>>((resolve) => {
    const cancelTokenSource = axios.CancelToken.source()

    const connectionTimer = setTimeout(() => {
      cancelTokenSource.cancel()
    }, AppConfig.CONNECTION_TIMEOUT)

    const session = getSession()

    const headers =
      session !== null
        ? {
            Authorization: `${session.auth.token_type} ${session.auth.access_token}`
          }
        : {}

    let cache: ISetupCache | undefined
    if (options?.cache !== undefined) {
      setupCache({
        // https://github.com/RasCarlito/axios-cache-adapter#setupcacheoptions
        readHeaders: false,
        maxAge: AppConfig.CACHE_MAX_AGE_MINUTES * 60 * 1000,
        store: cacheStore,
        key: () => options.cache!.key
      })
    }

    axios({
      method,
      url: `${AppConfig.API_BASEURL}${url}`,
      data,
      headers,
      cancelToken: cancelTokenSource.token,
      adapter: cache?.adapter
    })
      .then((res) => {
        clearTimeout(connectionTimer)
        const fromCache = res.request?.fromCache === true
        resolve({ error: false, data: res.data, fromCache })
      })
      .catch((err: AxiosError) => {
        clearTimeout(connectionTimer)

        if (axios.isCancel(err)) {
          return resolve({
            error: true,
            status: AGENT_ERROR_TIMEOUT
          })
        } else if (err.response) {
          // TODO: token renewal
          /*
          if (
            err.response.status === 403 ||
            (err.response.status === 401 && options?.renewToken === false)
          ) {
            return resolve({
              error: true,
              status: 403
            })
          } else if (err.response.status === 401) {
            if (session.state === SessionState.SIGNED_IN) {
              const authData = `refresh_token=${session.auth.refresh_token}&grant_type=refresh_token`
              axios
                .post('/oauth/token', authData, {
                  auth: {
                    username: EnvConfig.AUTH_USERNAME,
                    password: EnvConfig.AUTH_PASSWORD
                  },
                  baseURL: '/api'
                })
                .then((res) => {
                  if (res.status === 200) {
                    nextSession({
                      state: SessionState.SIGNED_IN,
                      auth: res.data
                    })
                    makeAgentRequest<T>(method, url, data, {
                      renewToken: false
                    }).then(resolve)
                  }
                })
                .catch(() => {
                  resolve({
                    error: true,
                    status: 403
                  })
                })
            }
            return
          }*/

          console.log('*** http_error', err.response)

          return resolve({
            error: true,
            status: err.response.status,
            data: err.response.data
          })
        }

        resolve({
          error: true,
          status: AGENT_ERROR_UNKNOWN
        })
      })
  })

// fetch

export function makeFetch<R extends FetchRequest>(
  method: FetchMethod,
  path: string,
  body: any,
  options?: FetchRequestOptions
) {
  return makeAgentRequest<R>(method, path, body, options)
}

export type FetchHookLeft<R extends FetchRequest = void> =
  | {
      state: Exclude<FetchState, FetchState.OK | FetchState.ERROR>
    }
  | ({ state: FetchState.ERROR } & AgentErrorResponse)
  | { state: FetchState.OK; data: R; fromCache: boolean }

export type FetchHookRight<B extends FetchBody = undefined> = {
  fetch: (body?: B) => void
  setSuffix: (value: string) => void
  setHandleErrors: (value: boolean) => void
}

export function useFetch<
  R extends FetchRequest = void,
  B extends FetchBody = undefined
>(
  method: FetchMethod,
  path: string,
  options?: FetchRequestOptions
): [FetchHookLeft<R>, FetchHookRight<B>] {
  const [fetchState, setFetchState] = useSafeState<FetchHookLeft<R>>({
    state: FetchState.IDLE
  })
  const urlSuffixRef = useRef<string>('')
  const handleErrors = useRef<boolean>(true)

  const dispatcher = useMemo<FetchHookRight<B>>(() => {
    const setSuffix = (value: string) => (urlSuffixRef.current = value)

    const setHandleErrors = (value: boolean) => (handleErrors.current = value)

    const fetch = (body?: B) => {
      if (fetchState.state !== FetchState.FETCHING) {
        setFetchState({ state: FetchState.FETCHING })
      }

      const fetcher = makeAgentRequest<R>(
        method,
        `${path}${urlSuffixRef.current}`,
        body,
        options
      )

      fetcher.then((res) => {
        if (res.error) {
          if (handleErrors.current) {
            if (res.status === 403) {
              message.error(getStrings().app.error.sessionExpired)
              // kick out
              clearSession()
            } else {
              message.error(sanitizeFetchErrorDataAsMessage(res))
            }
          }

          return setFetchState({
            state: FetchState.ERROR,
            ...(res as AgentErrorResponse)
          })
        }

        const { data, fromCache } = res as AgentSuccessResponse<R>
        setFetchState({
          state: FetchState.OK,
          data,
          fromCache
        })
      })
    }

    return { fetch, setSuffix, setHandleErrors }
  }, [])

  return [fetchState, dispatcher]
}

// mocks

type MockRequestOptions<R> = { mockResponse: R }

export function makeMockFetch<R extends FetchRequest>(
  _method: FetchMethod,
  _path: string,
  _body: any,
  options: FetchRequestOptions & MockRequestOptions<R>
) {
  return new Promise<AgentResponse<R>>((resolve) => {
    setTimeout(() => {
      resolve({ error: false, data: options.mockResponse, fromCache: false })
    }, 306)
  })
}

export function useMockFetch<
  R extends FetchRequest = void,
  B extends FetchBody = undefined
>(
  _method: FetchMethod,
  _path: string,
  options: FetchRequestOptions & MockRequestOptions<R>
): [FetchHookLeft<R>, FetchHookRight<B>] {
  const [fetchState, setFetchState] = useSafeState<FetchHookLeft<R>>({
    state: FetchState.IDLE
  })
  const urlSuffixRef = useRef<string>('')
  const handleErrors = useRef<boolean>(true)

  const dispatcher = useMemo<FetchHookRight<B>>(() => {
    const setSuffix = (value: string) => (urlSuffixRef.current = value)

    const setHandleErrors = (value: boolean) => (handleErrors.current = value)

    const fetch = (_body?: B) => {
      if (fetchState.state !== FetchState.FETCHING) {
        setFetchState({ state: FetchState.FETCHING })
      }

      setTimeout(() => {
        setFetchState({
          state: FetchState.OK,
          data: options.mockResponse,
          fromCache: false
        })
      }, 306)
    }

    return { fetch, setSuffix, setHandleErrors }
  }, [])

  return [fetchState, dispatcher]
}
