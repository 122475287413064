import React from 'react'
import { WithClassNameProp } from '~component/types'
import useStrings from '~i18n/useStrings'
import logo from './Logo.png'

// TODO: deprecated

export default function Logo(props: WithClassNameProp) {
  const [{ app: strings }] = useStrings()

  return (
    <img src={logo} alt={strings.global.title} className={props.className} />
  )
}
