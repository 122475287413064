import fuzzysort from 'fuzzysort'

export type FuzzySearch<T> = {
  setData: (data: Array<T>) => void
  query: (q: string) => Array<T>
}

export default function makeFuzzySearch<
  T extends {
    [k: string]: any
  }
>(keys: Array<keyof T>): FuzzySearch<T> {
  const SEARCH_OPTIONS = {
    keys
  } as { keys: ReadonlyArray<string> }

  let data: Array<T> = []

  return {
    setData: (nextData: Array<T>) => (data = nextData),
    query: (q: string): Array<T> => {
      if (!q.length) {
        return []
      }

      const results = fuzzysort.go<T>(q, data, SEARCH_OPTIONS)
      return results.map(({ obj }) => obj)
    }
  }
}
