// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ODdUTItX-z-lLGK0G7mOZ {\n  background-color: white;\n}\n.NNnGkm7sa63C8Ep6VzgK6 {\n  background-color: #f0f2f5;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"root": "ODdUTItX-z-lLGK0G7mOZ",
	"content": "NNnGkm7sa63C8Ep6VzgK6"
};
module.exports = exports;
