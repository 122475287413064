import { Grid } from 'antd'
import React, { useMemo } from 'react'
import { WithChildrenProp, WithClassNameProp } from '~component/types'

const { useBreakpoint } = Grid

export const useSpaceSize = () => {
  const size = useBreakpoint()
  return size.md ? 24 : 16
}

export type AppSpacedProps = WithChildrenProp &
  WithClassNameProp & {
    nh?: boolean
    nv?: boolean
    nt?: boolean
    nr?: boolean
    nb?: boolean
    nl?: boolean
  }

export default function AppSpaced(props: AppSpacedProps) {
  const size = useSpaceSize()

  const { nh, nv, nt, nr, nb, nl, children } = props

  const style = useMemo<React.CSSProperties>(() => {
    let paddingLeft = 0,
      paddingRight = 0,
      paddingTop = 0,
      paddingBottom = 0

    if (nv !== true) {
      paddingTop = paddingBottom = size
    }
    if (nh !== true) {
      paddingLeft = paddingRight = size
    }
    if (nt === true) {
      paddingTop = 0
    }
    if (nr === true) {
      paddingRight = 0
    }
    if (nb === true) {
      paddingBottom = 0
    }
    if (nl === true) {
      paddingLeft = 0
    }

    return {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom
    }
  }, [size, props])

  return (
    <div className={props.className} style={style}>
      {children}
    </div>
  )
}
