import React from 'react'
import { WithChildrenProp } from '~component/types'
import style from './AdminContent.less'

// TODO: deprecated

export default function AdminContent(props: WithChildrenProp) {
  return (
    <div className={style.root}>
      <div className={style.inner}>{props.children}</div>
    </div>
  )
}
