import makeStore from '~lib/base/makeStore'
import { AuthDTO } from '~service/dto'

export type Session = {
  auth: AuthDTO
  god: boolean
}

const {
  init: initSession,
  addListener: addSessionListener,
  getValue: getSession,
  nextValue: nextSession,
  clearValue: clearSession,
  useStore: useSession
} = makeStore<Session>('session')

const initMockAdminSessionStore = () => {
  nextSession({
    auth: { id_token: 'string', access_token: 'string', token_type: 'string' },
    god: false
  })
}

export {
  initSession,
  initMockAdminSessionStore,
  addSessionListener,
  getSession,
  clearSession,
  nextSession
}

export default useSession
