// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zE_inFoNgYCklhN96EKb4 {\n  padding-top: 0;\n  padding-left: 0;\n  padding-right: 0;\n}\n._3MF_JDqcaf0-4zrEYnOYn- {\n  min-height: 300px;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"header": "zE_inFoNgYCklhN96EKb4",
	"card": "_3MF_JDqcaf0-4zrEYnOYn-"
};
module.exports = exports;
