import { Card, PageHeader, Skeleton, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import React, { useEffect, useMemo, useReducer } from 'react'
import MotionContent from '~component/widget/MotionContent'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import useStrings from '~i18n/useStrings'
import { getArrayPage } from '~lib/pagedArray'
import { AssignTenantToCustomerLink } from '~navigation/links'
import { useFetchCustomers } from '~service/customers'
import { CustomerDTO, CustomerGender } from '~service/dto'
import { FetchState } from '~service/useFetch'
import styles from './Customers.less'

const PAGE_SIZE = 20

export default function TenantsScreen() {
  const [{ app: strings }] = useStrings()
  const [customers, { fetch: customersFetch }] = useFetchCustomers()

  const columns = useMemo(() => {
    return [
      {
        title: strings.customers.email,
        dataIndex: 'email'
        /*render: (_email: string, item: CustomerItemDTO) => {
          return <EditCustomerLink customer={item} />
        }*/
      },
      {
        title: strings.customers.name,
        dataIndex: 'name'
      },
      {
        title: strings.customers.surname,
        dataIndex: 'surname'
      },
      {
        title: strings.customers.birth_date,
        dataIndex: 'birth_date',
        render: (d: string | undefined) => {
          if (!d) {
            return ''
          }
          return new Date(d).toLocaleDateString()
        }
      },
      {
        title: strings.customers.phone,
        dataIndex: 'phone'
      },
      {
        title: strings.customers.gender,
        dataIndex: 'gender',
        render: (_text: string, record: CustomerDTO) => {
          return record.gender === CustomerGender.MALE
            ? strings.customers.genderMale
            : strings.customers.genderFemale
        }
      },
      {
        title: '',
        render: (_text: string, record: CustomerDTO) => {
          return <AssignTenantToCustomerLink customer={record} />
        }
      }
    ]
  }, [strings])

  // state

  type State = {
    loading: boolean
    data: Array<CustomerDTO>
    pageData: Array<CustomerDTO>
    pagination: TablePaginationConfig
  }
  type Action =
    | {
        kind: 'init'
        payload: Array<CustomerDTO>
      }
    | {
        kind: 'page'
        payload: number
      }

  const [state, dispatch] = useReducer(
    (state: State, action: Action): State => {
      if (action.kind === 'init') {
        return {
          loading: false,
          data: action.payload,
          pageData: getArrayPage(action.payload, 1, PAGE_SIZE),
          pagination: {
            total: action.payload.length,
            current: 1,
            pageSize: PAGE_SIZE,
            onChange: (payload) => {
              dispatch({ kind: 'page', payload })
            }
          }
        }
      } else if (action.kind === 'page') {
        return {
          loading: false,
          data: state.data,
          pageData: getArrayPage(state.data, action.payload, PAGE_SIZE),
          pagination: {
            total: state.pagination.total,
            current: action.payload,
            pageSize: state.pagination.pageSize,
            onChange: state.pagination.onChange
          }
        }
      }
      return state
    },
    { loading: true, pageData: [], data: [], pagination: {} }
  )

  // flow

  useEffect(() => {
    customersFetch()
  }, [])

  useEffect(() => {
    if (customers.state === FetchState.OK) {
      dispatch({ kind: 'init', payload: customers.data })
    }
    // TODO: errors
  }, [customers])

  return (
    <>
      <AppBrowserTitle text={strings.customers.title} />
      <MotionContent>
        <PageHeader className={styles.header} title={strings.customers.title} />
        <Card className={styles.card}>
          {state.loading && <Skeleton active />}
          {!state.loading && (
            <Table<CustomerDTO>
              rowKey="id"
              dataSource={state.pageData}
              columns={columns as any}
              pagination={state.pagination}
            />
          )}
        </Card>
      </MotionContent>
    </>
  )
}
