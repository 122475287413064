import * as localforage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { skip } from 'rxjs/internal/operators/skip'
import useFacade from '~lib/base/useFacade'

localforage.defineDriver(memoryDriver)

const persistedInstance = localforage.createInstance({})
const inMemoryInstance = localforage.createInstance({
  driver: memoryDriver._driver
})

export const clearStores = () => localforage.clear()

export type Store<T> = null | T

export default function makeStore<T extends object>(
  key: string,
  options?: { inMemory?: boolean; defaultValue?: T }
) {
  const instance =
    options?.inMemory !== true ? persistedInstance : inMemoryInstance

  const subject = new BehaviorSubject<Store<T>>(null)
  subject.pipe(skip(1)).subscribe((value) => {
    try {
      if (value !== null) {
        instance.setItem(key, value)
      } else {
        instance.removeItem(key)
      }
    } catch {}
  })

  const init = async () => {
    let value: T | null = null
    try {
      value = await instance.getItem<Store<T>>(key)
    } catch {}

    if (value !== null) {
      nextValue(value)
    } else if (options?.defaultValue) {
      nextValue(options?.defaultValue)
    }
  }

  const addListener = (listener: (value: Store<T>) => void) => {
    return subject.pipe(skip(1)).subscribe(listener)
  }

  const getValue = () => subject.getValue()

  const nextValue = (value: T) => {
    subject.next(value)
  }

  const clearValue = () => {
    subject.next(null)
  }

  const useStore = (): [Store<T>, (next: T) => void] => {
    const [L] = useFacade(subject)
    return [L, nextValue]
  }

  return { init, addListener, getValue, nextValue, clearValue, useStore }
}
