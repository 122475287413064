import { Card, Popconfirm, Skeleton, Typography, message } from 'antd'
import React, { useCallback, useEffect, useMemo } from 'react'
import AppSpaced from '~component/layout/AppSpaced'
import { TenantCustomerDetailLink } from '~component/screen/tenantCustomer/TenantCustomerDetail'
import useTenantCustomersState from '~component/screen/tenantCustomer/TenantCustomers.state'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import AppDataGrid, {
  AppDataGridColum,
  DEFAULT_PAGE_SIZE
} from '~component/widget/AppDataGrid'
import useStrings, { getStrings } from '~i18n/useStrings'
import ensureNumber from '~lib/ensureNumber'
import { makeScreenNavigation } from '~navigation/base/makeScreenNavigation'
import { ScreenPath } from '~navigation/types'
import {
  TenantCustomersItem,
  useFetchTenantCustomers,
  useFetchDeleteTenantCustomer
} from '~service/tenantCustomers'
import { FetchState } from '~service/useFetch'

const { Text, Link } = Typography

export type TenantCustomersScreenNavigationParams = {
  pageSize?: string
  page?: string
}

const {
  useScreenNavigation,
  useGoToScreen: useGoToTenantCustomers,
  ScreenLink: TenantCustomersLink
} = makeScreenNavigation<TenantCustomersScreenNavigationParams>((params) => {
  if (params.page === undefined && params.pageSize === undefined) {
    return `/${ScreenPath.CUSTOMERS}`
  }
  return `/${ScreenPath.CUSTOMERS}/${params.page ?? 1}/${
    params.pageSize ?? DEFAULT_PAGE_SIZE
  }`
})

export { useGoToTenantCustomers, TenantCustomersLink }

export const TenantCustomersPath = `/${ScreenPath.CUSTOMERS}`
export const TenantCustomersPagedPath = `/${ScreenPath.CUSTOMERS}/:page/:pageSize`

export default function TenantCustomersScreen() {
  const [{ app: strings }] = useStrings()

  const goToTenantCustomers = useGoToTenantCustomers()
  const { navigationParams } = useScreenNavigation()

  const [customers, { fetch: fetchTenantCustomers }] = useFetchTenantCustomers()
  const [
    deleteCustomer,
    { fetch: fetchDeleteCustomer }
  ] = useFetchDeleteTenantCustomer()

  const [state, dispatch] = useTenantCustomersState()

  const page = ensureNumber(navigationParams.page, 1)
  const pageSize = ensureNumber(navigationParams.pageSize, DEFAULT_PAGE_SIZE)

  useEffect(() => {
    fetchTenantCustomers()
  }, [])

  useEffect(() => {
    if (customers.state === FetchState.OK) {
      dispatch({ type: 'init', payload: customers.data })
    }
  }, [customers])

  useEffect(() => {
    if (deleteCustomer.state === FetchState.OK) {
      message.success(getStrings().app.tenantCustomers.successDelete)
    }
  }, [deleteCustomer])

  const columns = useMemo<Array<AppDataGridColum<TenantCustomersItem>>>(() => {
    return [
      {
        title: strings.customers.email,
        render: (customer) => `${customer.email}`
      },
      {
        title: strings.customers.name,
        render: (customer) => `${customer.name}`
      },
      {
        title: strings.customers.surname,
        render: (customer) => `${customer.surname}`
      },
      {
        title: strings.customers.birth_date,
        render: (customer) =>
          `${new Date(customer.birth_date).toLocaleDateString()}`
      },
      {
        title: strings.customers.phone,
        render: (customer) => `${customer.phone}`
      },
      {
        title: strings.global.action,
        render: (customer) => {
          return (
            <>
              <Popconfirm
                title={strings.tenantCustomers.confirmDelete}
                onConfirm={() => {
                  dispatch({ type: 'remove', payload: customer })
                  fetchDeleteCustomer()
                }}
              >
                <Link>{strings.global.delete}</Link>
              </Popconfirm>
              <Text> | </Text>
              <TenantCustomerDetailLink
                params={{ id: customer.id }}
                state={{
                  data: customer,
                  customersPagination: { page, pageSize }
                }}
              >
                {strings.global.view}
              </TenantCustomerDetailLink>
            </>
          )
        }
      }
    ]
  }, [strings, page, pageSize])

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    goToTenantCustomers({ page: `${page}`, pageSize: `${pageSize}` })
  }, [])

  return (
    <>
      <AppBrowserTitle text={strings.tenantCustomers.title} />
      <AppSpaced>
        <Card title={strings.tenantCustomers.title}>
          {customers.state !== FetchState.OK && <Skeleton active />}
          {state.data !== undefined && (
            <AppDataGrid<TenantCustomersItem>
              dataKey="id"
              page={page}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              columns={columns}
              data={state.data}
            />
          )}
        </Card>
      </AppSpaced>
    </>
  )
}
