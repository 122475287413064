declare var process: {
  env: { API_BASEURL: string; CACHE_KEY: string; CACHE_MAX_AGE_MINUTES: string }
}

const AppConfig = {
  API_BASEURL: process.env.API_BASEURL!,
  CONNECTION_TIMEOUT: 6120,
  CACHE_KEY: process.env.CACHE_KEY!,
  CACHE_MAX_AGE_MINUTES: parseInt(process.env.CACHE_MAX_AGE_MINUTES)
}
export default AppConfig
