// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".oHRwVWOsbaTcphxO110Jt {\n  display: flex;\n  flex-direction: row;\n}\n._3aNgMs2yh5b_S9qlbJu1-v {\n  background-color: #f6f6f6 !important;\n  color: black !important;\n  cursor: auto !important;\n  border: 0 !important;\n  width: 300px !important;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"root": "oHRwVWOsbaTcphxO110Jt",
	"input": "_3aNgMs2yh5b_S9qlbJu1-v"
};
module.exports = exports;
