// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CzktLLybOTuFd6L3EyrrW {\n  flex-direction: row;\n  align-items: flex-start;\n}\n._152unjM_v7qTxpmo-OTHC2 {\n  width: 90px;\n  text-align: center;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"root": "CzktLLybOTuFd6L3EyrrW",
	"item": "_152unjM_v7qTxpmo-OTHC2"
};
module.exports = exports;
