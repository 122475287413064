import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ScreenPath } from '~navigation/types'
import { TenantDTO } from '~service/dto'
import { TenantsResponseItem } from '~service/tenants'

// TODO: deprecated this, use screenNavigation

type L = {
  underTenants: boolean
  underCustomers: boolean
  underMyCompany: boolean
}
type R = {
  goToTenants: () => void
  goToAddNewTenant: () => void
  goToTenant: (tenant: TenantsResponseItem | TenantDTO) => void
  goToCustomers: () => void
}

const LfromPath = (pathname: string): L => {
  return {
    underTenants: pathname.indexOf(`/${ScreenPath.TENANTS}`) === 0,
    underCustomers: pathname.indexOf(`/${ScreenPath.CUSTOMERS}`) === 0,
    underMyCompany: pathname.indexOf(`/${ScreenPath.MY_COMPANY}`) === 0
  }
}

export default function useNavigation(): [L, R] {
  const history = useHistory()
  const location = useLocation()

  const [l, setL] = useState<L>(() => {
    return LfromPath(location.pathname)
  })
  const firstL = useRef<boolean>(true)

  useEffect(() => {
    if (firstL.current) {
      // do not trigger a re-render on init, since we already know the location
      firstL.current = false
      return
    }

    setL({
      underTenants: location.pathname.indexOf(`/${ScreenPath.TENANTS}`) === 0,
      underCustomers:
        location.pathname.indexOf(`/${ScreenPath.CUSTOMERS}`) === 0,
      underMyCompany:
        location.pathname.indexOf(`/${ScreenPath.MY_COMPANY}`) === 0
    })
  }, [location.pathname])

  const r: R = useMemo(() => {
    return {
      goToTenants: () => {
        history.replace(`/${ScreenPath.TENANTS}`)
      },
      goToAddNewTenant: () => {
        history.push(`/${ScreenPath.TENANTS}/${ScreenPath.TENANTS_ADD_NEW}`)
      },
      goToTenant: (tenant: TenantsResponseItem | TenantDTO) => {
        history.replace(`/${ScreenPath.TENANTS}/${tenant.id}`, tenant)
      },
      goToCustomers: () => {
        history.replace(`/${ScreenPath.CUSTOMERS}`)
      }
    }
  }, [history])

  return [l, r]
}
