import { useEffect, useRef, useState } from 'react'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { skip } from 'rxjs/internal/operators/skip'
import { Subscription } from 'rxjs/internal/Subscription'

export default function useFacade<T>(
  subject: BehaviorSubject<T>
): [T, (next: T) => void] {
  const subscriptionRef = useRef<Subscription>()
  const isUnmounted = useRef(false)

  const [state, setState] = useState<T>(subject.getValue())

  useEffect(() => {
    isUnmounted.current = false

    subscriptionRef.current = subject.pipe(skip(1)).subscribe((next) => {
      // double check if component is unmounted
      if (!isUnmounted.current) {
        setState(next)
      }
    })

    return () => {
      isUnmounted.current = true
      if (subscriptionRef.current) {
        subscriptionRef.current?.unsubscribe()
      }
      subscriptionRef.current = undefined
    }
  }, [])

  return [state, (next: T) => subject.next(next)]
}
