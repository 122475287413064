import React, { useState, useEffect, useCallback } from 'react'
import { Input, Button, message } from 'antd'
import styles from './SecretText.less'
import { EyeOutlined, CopyOutlined } from '@ant-design/icons'
import Clipboard from 'react-clipboard.js'
import { getStrings } from '~i18n/useStrings'

export type SecretTextProps = { text: string }

class CustomButton extends React.Component {
  render() {
    const { style, className, ...rest } = this.props as any

    return <Button {...rest} type='link' icon={<CopyOutlined />} />
  }
}

export default function SecretText(props: SecretTextProps) {
  const [see, setSee] = useState<boolean>(false)

  const handleSuccess = useCallback(() => {
    message.success(getStrings().app.global.copyToClipboard)
  }, [])

  return (
    <div className={styles.root}>
      <Input
        disabled={!see}
        contentEditable={false}
        type={see ? 'text' : 'password'}
        value={props.text}
        className={styles.input}
      />
      <Button type='link' icon={<EyeOutlined />} onClick={() => setSee(!see)} />
      <Clipboard
        data-clipboard-text={props.text}
        component={CustomButton}
        onSuccess={handleSuccess}
      />
    </div>
  )
}
