// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2M_yWDGLiGZ85QLvRtJHp {\n  padding-top: 0;\n  padding-left: 0;\n  padding-right: 0;\n}\n._1vMIrzBekMmUQWTCgygfTd {\n  min-height: 200px;\n}\n._1inZTU7L57wFvxgfpg__wu {\n  color: green;\n}\n._2KsfUGJuWi1kijPM-WEJPt {\n  color: red;\n}\n._2VPc7WJe-UIYbedMUAnT5J {\n  width: 100px;\n}\n.ga6t4UQSA6v7kwYGtXkFq {\n  margin-bottom: 10px;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"header": "_2M_yWDGLiGZ85QLvRtJHp",
	"card": "_1vMIrzBekMmUQWTCgygfTd",
	"greenIcon": "_1inZTU7L57wFvxgfpg__wu",
	"redIcon": "_2KsfUGJuWi1kijPM-WEJPt",
	"addRoleInput": "_2VPc7WJe-UIYbedMUAnT5J",
	"role": "ga6t4UQSA6v7kwYGtXkFq"
};
module.exports = exports;
