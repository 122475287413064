import {
  ApiOutlined,
  FormOutlined,
  ProfileOutlined,
  TagsOutlined,
  TeamOutlined
} from '@ant-design/icons'
import React from 'react'
import AppSpaced from '~component/layout/AppSpaced'
import AppTabbedCard from '~component/layout/AppTabbedCard'
import AppBrowserTitle from '~component/widget/AppBrowserTitle'
import useStrings from '~i18n/useStrings'
import { makeScreenNavigation } from '~navigation/base/makeScreenNavigation'
import { ScreenPath } from '~navigation/types'

export const MyCompanyPath = `/${ScreenPath.MY_COMPANY}`

const {
  useGoToScreen: useGoToMyCompany,
  ScreenLink: MyCompanyLink
} = makeScreenNavigation(() => `/${ScreenPath.MY_COMPANY}`)

export { useGoToMyCompany, MyCompanyLink }

export default function MyCompanyScreen() {
  const [{ app: strings }] = useStrings()

  return (
    <>
      <AppBrowserTitle text={strings.myCompany.title} />
      <AppSpaced>
        <AppTabbedCard title={strings.myCompany.title} defaultActiveTab="info">
          <AppTabbedCard.Tab
            title={strings.myCompany.tabInfo}
            key="info"
            icon={ProfileOutlined}
          >
            CONTENT 1
          </AppTabbedCard.Tab>
          <AppTabbedCard.Tab
            title={strings.myCompany.tabFields}
            key="fields"
            icon={FormOutlined}
          >
            CONTENT 2
          </AppTabbedCard.Tab>
          <AppTabbedCard.Tab
            title={strings.myCompany.tabTags}
            key="tags"
            icon={TagsOutlined}
          >
            CONTENT 3
          </AppTabbedCard.Tab>
          <AppTabbedCard.Tab
            title={strings.myCompany.tabRoles}
            key="roles"
            icon={TeamOutlined}
          >
            CONTENT 4
          </AppTabbedCard.Tab>
          <AppTabbedCard.Tab
            title={strings.myCompany.tabServices}
            key="services"
            icon={ApiOutlined}
          >
            CONTENT 4
          </AppTabbedCard.Tab>
        </AppTabbedCard>
      </AppSpaced>
    </>
  )
}
