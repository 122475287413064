// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._35yhFBLgWHe8I7l8Sm8goH {\n  width: 100%;\n  flex: 1;\n}\n/* overwrite antd */\n", ""]);
// Exports
exports.locals = {
	"root": "_35yhFBLgWHe8I7l8Sm8goH"
};
module.exports = exports;
