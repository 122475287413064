import { Typography } from 'antd'
import React, { useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { WithChildrenProp } from '~component/types'

const { Link: TypographyLink } = Typography

export function makeScreenNavigation<
  P extends object = {},
  S extends object | undefined = undefined
>(pathnameBuilder: (params: P) => string) {
  return {
    useScreenNavigation: () => {
      const navigationParams = useParams<P>()
      const navigationState = useLocation<S | undefined>().state

      return { navigationParams, navigationState }
    },
    useGoToScreen: () => {
      const history = useHistory()
      const callback = useCallback(
        (params: P, state?: S) => {
          history.push({
            pathname: pathnameBuilder(params),
            state
          })
        },
        [history]
      )

      return callback
    },
    ScreenLink: (props: WithChildrenProp & { params: P; state?: S }) => {
      const { params, state, children } = props
      const history = useHistory()

      const handleClick = useCallback(() => {
        history.push({
          pathname: pathnameBuilder(params),
          state
        })
      }, [history, params, state])

      return <TypographyLink onClick={handleClick}>{children}</TypographyLink>
    }
  }
}
