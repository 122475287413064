import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'
import useStrings from '~i18n/useStrings'
import { PickerProps } from 'antd/lib/date-picker/generatePicker'

const BaseDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

export default function DatePicker(props: PickerProps<Dayjs>) {
  const { value, ...rest } = props
  const [{ date: locale }] = useStrings()

  let nextValue = value
  if (typeof nextValue === 'string') {
    nextValue = dayjs(nextValue)
  }

  return (
    <BaseDatePicker
      {...rest}
      format="DD/MM/YYYY"
      value={nextValue}
      locale={locale}
    />
  )
}
