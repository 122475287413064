// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,\nbody,\n#_3-5HScD1UY0UlSjPhi2Jo1 {\n  margin: 0;\n  padding: 0;\n  height: 100vh;\n}\n\n/* Change Autocomplete styles in Chrome*/\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ntextarea:-webkit-autofill,\ntextarea:-webkit-autofill:hover,\ntextarea:-webkit-autofill:focus,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus {\n  border: 0;\n  -webkit-text-fill-color: inherit;\n  -webkit-box-shadow: 0 0 0px 0px white inset;\n  transition: background-color 5000s ease-in-out 0s;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_3-5HScD1UY0UlSjPhi2Jo1"
};
module.exports = exports;
